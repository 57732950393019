import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import {
  ContactLink,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';

const PNAdministratorsViewCityBoard = ({ content }) => {
  const intl = useIntl();

  return content.viewtype.title === 'Giunta comunale' ||
    content.viewtype.title === 'Giunta Comunale' ? (
    content?.Administrators.map((item, index) => (
      <>
        <RichTextSection
          tag_id={item.lastName}
          title={item.firstName}
          show_title={false}
        >
          <div class="it-list-wrapper">
            <ul className="it-list commission-list p-0">
              <li>
                <div class="list-item">
                  <div class="it-right-zone align-items-start">
                    <span class="text">
                      <a href={flattenToAppURL(item?.url)}>{item?.name}</a>
                      {item?.roles &&
                        Object.entries(item.roles).map(
                          ([key, value], roleIndex) => (
                            <em key={`role-${roleIndex}`}> {value}</em>
                          ),
                        )}
                    </span>
                    <span className="it-multiple">
                      <span className="metadata align-items-start">
                        <p>
                          <ContactLink email={item?.email} label={false} />
                        </p>
                        <p>
                          <ContactLink tel={item?.phone} label={false} />
                        </p>
                      </span>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </RichTextSection>
      </>
    ))
  ) : (
    <></>
  );
};

PNAdministratorsViewCityBoard.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorsViewCityBoard;
