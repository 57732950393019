/**
 * PNEventContainer view component.
 * @module components/theme/View/PNEventContainerView
 */
import { defineMessages, useIntl } from 'react-intl';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  PageHeader,
  SkipToMainContent,
  WideImage,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { PNAlertBehavior } from 'comune-pordenone-plone-theme/components/View';

import {
  Container,
  Row,
  FormGroup,
  Input,
  Toggle,
  Button,
} from 'design-react-kit';
import PNEventsList from './PNEventsList';
import PNHighlightEvents from './PNHighlightEvents';

const messages = defineMessages({
  readMore: {
    id: 'readMore',
    defaultMessage: 'Leggi di più',
  },
  when: {
    id: 'when',
    defaultMessage: 'Quando?',
  },
  today: {
    id: 'today',
    defaultMessage: 'Oggi',
  },
  next7: {
    id: 'next7',
    defaultMessage: 'Prossimi 7 giorni',
  },
  next30: {
    id: 'next30',
    defaultMessage: 'Prossimi 30 giorni',
  },
  eventFilters: {
    id: 'eventFilters',
    defaultMessage: 'Filtra eventi',
  },
  forChildFilter: {
    id: 'forChildFilter',
    defaultMessage: 'Per bambini?',
  },
  onlineFilter: {
    id: 'onlineFilter',
    defaultMessage: 'È un evento online?',
  },
  textFilter: {
    id: 'textFilter',
    defaultMessage: 'Cerca negli eventi',
  },
  chooseOption: {
    id: 'chooseOption',
    defaultMessage: "Scegli un'opzione",
  },
  search: {
    id: 'search',
    defaultMessage: 'Cerca',
  },
});
/**
 * PNEventContainerView view component class.
 * @function PNEventContainerView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNEventContainerView = ({ content, location }) => {
  const intl = useIntl();
  const [readingtime, setReadingtime] = useState(0);
  const [dateFilter, setDateFilter] = useState(false);
  const [forChildrenFilter, setForChildrenFilter] = useState(false);
  const [onlineFilter, setOnlineFilter] = useState(false);
  const [textFilter, setTextFilter] = useState('');
  const [highlight, setHighlight] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [highlightItems, setHighlightItems] = useState([]);

  const searchText = () => {
    const searchValue = document.getElementById('textFilter');
    setTextFilter(searchValue?.value);
  };

  return content?.items ? (
    <Container className="my-4 events-view">
      <div className="px-4 px-lg-0">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showtassonomiaargomenti={true}
          showdates={true}
        />
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
        />
        <WideImage
          title={content?.title}
          image={content}
          caption={content?.image_caption}
          fullWidth={true}
        />
        <Row className="border rounded" id={'filter-section'}>
          <h3 className="p-3 p-md-5">
            {intl.formatMessage(messages.eventFilters)}
          </h3>
          <FormGroup check className="py-4">
            <Row className="justify-content-between px-5">
              <div className="mt-0 col-8 col-md-4 col-lg-3 form-check form-check-group shadow-none select-wrapper">
                <label for="dateFilter">
                  {intl.formatMessage(messages.when)}
                </label>
                <select
                  id="dateFilter"
                  value={dateFilter}
                  onChange={(e) => {
                    setDateFilter(e.target.value);
                  }}
                  className="shadow-none form-check select-wrapper"
                >
                  <option value="">
                    {intl.formatMessage(messages.chooseOption)}
                  </option>
                  <option value="today">
                    {intl.formatMessage(messages.today)}
                  </option>
                  <option value="next7">
                    {intl.formatMessage(messages.next7)}
                  </option>
                  <option value="next30">
                    {intl.formatMessage(messages.next30)}
                  </option>
                </select>
              </div>
              <div className="col-12 col-md-4 col-lg-3 font-weight-bold form-check shadow-none">
                <Toggle
                  label={intl.formatMessage(messages.forChildFilter)}
                  id="forChildFilter"
                  onChange={(e) => setForChildrenFilter(e.target.checked)}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-3 font-weight-bold form-check shadow-none">
                <Toggle
                  label={intl.formatMessage(messages.onlineFilter)}
                  id="forChildFilter"
                  onChange={(e) => setOnlineFilter(e.target.checked)}
                />
              </div>
            </Row>
            <Row className="px-5">
              <div className="form-check form-check-group shadow-none d-flex">
                <Input
                  id="textFilter"
                  type="text"
                  checked={textFilter}
                  placeholder={intl.formatMessage(messages.textFilter)}
                  className="col-10 mb-0"
                />
                <Button
                  color={'primary'}
                  onClick={searchText}
                  className="col mb-4"
                >
                  {intl.formatMessage(messages.search)}
                </Button>
              </div>
            </Row>
          </FormGroup>
        </Row>
        <PNHighlightEvents
          content={content}
          dateFilter={dateFilter}
          forChildrenFilter={forChildrenFilter}
          onlineFilter={onlineFilter}
          textFilter={textFilter}
          highlightItems={highlightItems}
          setHighlightItems={setHighlightItems}
        />
        <PNEventsList
          content={content}
          dateFilter={dateFilter}
          forChildrenFilter={forChildrenFilter}
          onlineFilter={onlineFilter}
          textFilter={textFilter}
          filteredItems={filteredItems}
          setFilteredItems={setFilteredItems}
        />
      </div>
    </Container>
  ) : (
    ''
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNEventContainerView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    effective: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    orari: PropTypes.shape({
      data: PropTypes.string,
    }),
    fee: PropTypes.shape({
      data: PropTypes.string,
    }),
    ulteriori_informazioni: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
    modified: PropTypes.string,
  }).isRequired,
};

export default PNEventContainerView;
