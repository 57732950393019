/**
 * Form widgets WfattachmentWidget module.
 * @module formwidgets/wfattachmentwidget
 *
 */

import { CardModel, ProcessInstanceModel, WidgetModel } from '../models';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import { getCardAttachmentsProxy, getCardsProxy } from '../proxies/classes';
import { useEffect, useState } from 'react';
import { extractFieldFeatureBindings, getFieldFeatureApis, getShowIfFn, updateVisibility } from './../utilities/fieldshelper';

import { expandToBackendURL } from '@plone/volto/helpers';

export const WfattachmentWidget = ({ config, target }: { config: WidgetModel; target: CardModel | ProcessInstanceModel | object }) => {
  const [wfAttachment, setWfAttachment] = useState();
  const [attachment, setAttachment] = useState();
  const [isOpen, toggleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const showIf = config.ShowIf || '';
  let showIfBindings;
  let showIfApis: any;
  let showIfFn: Function | undefined;

  if (showIf) {
    showIfBindings = extractFieldFeatureBindings(showIf, target);
    showIfApis = getFieldFeatureApis(target, {});
    showIfFn = getShowIfFn(showIf);
  }

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  useEffect(() => {
    getCardsProxy('WfAttachments', {
      filter: {
        attribute: {
          and: [
            {
              simple: {
                attribute: 'Workflow',
                operator: 'equal',
                value: target?.['_type' as keyof object],
              },
            },
            {
              simple: {
                attribute: 'Attribute',
                operator: 'equal',
                value: config.RelatedTo,
              },
            },
          ],
        },
      },
    }).then(({ data, meta }) => {
      setWfAttachment(data[0]);
    });
  }, []);

  useEffect(() => {
    if (wfAttachment) {
      getCardAttachmentsProxy('WfAttachments', wfAttachment._id, {}).then(({ data, meta }) => {
        setAttachment(data[0]);
        setIsLoading(false);
      });
    }
  }, [wfAttachment]);

  return (
    <div style={{ marginBottom: '2rem' }} hidden={!isVisible}>
      <span
        style={{ borderBottom: '1px solid', cursor: 'pointer', color: '#ff0400', marginTop: '1em' }}
        title={`Vedi documento ${config._label_translation}`}
        onClick={() => {
          toggleModal(!isOpen);
        }}
      >
        {config._label_translation}
      </span>
      <Modal isOpen={isOpen} toggle={() => toggleModal(!isOpen)} labelledBy={`widget-modal-${config._id}`} size='lg'>
        <ModalHeader toggle={() => toggleModal(!isOpen)} id={`widget-modal-${config._id}`}>
          {config._label_translation}
        </ModalHeader>
        <ModalBody style={{ height: window.innerHeight * 0.8 }}>
          {!isLoading ? (
            <iframe
              src={expandToBackendURL(`@c_get_file/classes/WfAttachments/cards/${wfAttachment?._id}/attachments/${attachment?._id}/${attachment?.name}`)}
              data-ref="iframeEl"
              width="100%"
              height="100%"
              frameBorder="0" />
          ): null}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};
