import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';

import { Metadata } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Chip, ChipLabel } from 'design-react-kit';

const messages = defineMessages({
  progressive_number: {
    id: 'progressive_number',
    defaultMessage: 'Numero progressivo: ',
  },
  categories: {
    id: 'categories',
    defaultMessage: 'Categorie: ',
  },
});

const PNNewsMoreInformation = ({ content }) => {
  const intl = useIntl();
  const showSectionTitle = useMemo(
    () => content?.numero_progressivo,
    [content],
  );

  return (
    <Metadata content={content} showSectionTitle={showSectionTitle}>
      {content?.numero_progressivo && (
        <div className="mt-4">
          <h6>
            {intl.formatMessage(messages.progressive_number)}
            <small>{content?.numero_progressivo}</small>
          </h6>
        </div>
      )}
      {content?.subjects?.length > 0 && (
        <>
          <h6>{intl.formatMessage(messages.categories)}</h6>
          <div className="d-flex flex-row flex-wrap justify-content-start">
            {content?.subjects?.map((subject, index) => (
              <div>
                <Chip
                  id={subject + '_' + index}
                  color="primary"
                  disabled={false}
                  large={false}
                  simple
                  tag="div"
                  className="m-1 text-decoration-none d-flex"
                >
                  <ChipLabel tag="span">{subject}</ChipLabel>
                </Chip>
              </div>
            ))}
          </div>
        </>
      )}
    </Metadata>
  );
};

PNNewsMoreInformation.propTypes = {
  content: PropTypes.shape({
    ulteriori_informazioni: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
  }).isRequired,
};

export default PNNewsMoreInformation;
