/**
 * Process Instance Form.
 * @module utilities/saverequestmodal
 *
 */

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  saveRequestModalTitle: {
    id: 'saveRequestModalTitle',
    defaultMessage: 'Richiesta salvata correttamente',
  },
  saveRequestModalText: {
    id: 'saveRequestModalText',
    defaultMessage: 'Come vuoi procedere?',
  },
  saveRequestModalContinue: {
    id: 'saveRequestModalContinue',
    defaultMessage: 'Continua la compilazione',
  },
  saveRequestModalGoReservedArea: {
    id: 'saveRequestModalGoReservedArea',
    defaultMessage: 'Vai all\'area riservata',
  },
});

export const SaveRequestModal = ({ isOpen, toggleModal }: { isOpen: boolean; toggleModal: Function }) => {
  const intl = useIntl();

  return (
    <Modal isOpen={isOpen} toggle={() => toggleModal(!isOpen)} labelledBy='save-request-modal'>
      <ModalHeader id='save-request-modal'>
        {intl.formatMessage(messages.saveRequestModalTitle)}
      </ModalHeader>
      <ModalBody>
        <p className='mb-3'>{intl.formatMessage(messages.saveRequestModalText)}</p>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={() => toggleModal(!isOpen)}>
          {intl.formatMessage(messages.saveRequestModalContinue)}
        </Button>
        <Button color='primary' 
          onClick={() => {
            toggleModal(!isOpen);
            window.location.href = '/my-area';
          }}
        >
          {intl.formatMessage(messages.saveRequestModalGoReservedArea)}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
