/**
 * Send Issues Email
 * @module src/actions/sendEmailIssue
 */
export const SEND_EMAIL_ISSUE = 'SEND_EMAIL_ISSUE';

export function sendEmailIssue(path, emailData) {
  return {
    type: SEND_EMAIL_ISSUE,
    request: {
      op: 'post',
      path: `${path}@issue-email-send`,
      headers: {
        Accept: 'application/json',
        Authorization: 'Basic ' + Buffer.from('admin:admin').toString('base64'),
      },
      data: emailData,
    },
  };
}
