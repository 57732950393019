/**
 * IssueStepperNav view component.
 * @module components/View/IssueStepperNav
 */

import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  StepperContainer,
  StepperNav,
  Row,
  Spinner,
} from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import { expandToBackendURL } from '@plone/volto/helpers';
import { sendEmailIssue } from 'comune-pordenone-plone-theme/actions';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

const messages = defineMessages({
  avaiableAppointments: {
    id: 'avaiableAppointments',
    defaultMessage: 'Appuntamenti disponibili',
  },
  officeDescription: {
    id: 'officeDescription',
    defaultMessage: "Scegli l'ufficio a cui vuoi richiedere l'appuntamento",
  },
  officeType: {
    id: 'officeType',
    defaultMessage: 'Tipologia di ufficio',
  },
  go_back: {
    id: 'go_back',
    defaultMessage: 'Indietro',
  },
  save_request: {
    id: 'save_request',
    defaultMessage: 'Salva richiesta',
  },
  advance: {
    id: 'advance',
    defaultMessage: 'Avanti',
  },
  infoPrivacy: {
    id: 'infoPrivacy',
    defaultMessage: 'Informativa privacy',
  },
  summary: {
    id: 'summary',
    defaultMessage: 'Riepilogo',
  },
  office: {
    id: 'office',
    defaultMessage: 'Ufficio',
  },
  time: {
    id: 'time',
    defaultMessage: 'Data e orario',
  },
  terms: {
    id: 'terms',
    defaultMessage: 'Termini e condizioni',
  },
  readTerms: {
    id: 'readTerms',
    defaultMessage: 'Leggi termini e condizioni',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Conferma e invia',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Annulla',
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'Nome',
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Cognome',
  },
  fiscalNumber: {
    id: 'fiscalNumber',
    defaultMessage: 'Codice fiscale',
  },
  address: {
    id: 'address',
    defaultMessage: 'Indirizzo',
  },
  email: {
    id: 'email',
    defaultMessage: 'E-mail',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Telefono',
  },
  reservation: {
    id: 'reservation',
    defaultMessage: 'Prenotazione',
  },
  confirm_text: {
    id: 'confirm_text',
    defaultMessage:
      'Cliccando su Conferma e invia confermi di aver preso visione dei termini e delle condizioni di servizio.',
  },
  issueType: {
    id: 'issueType',
    defaultMessage: 'Tipo di Disservizio',
  },
  details: {
    id: 'details',
    defaultMessage: 'Dettagli',
  },
  validateFields: {
    id: 'validateFields',
    defaultMessage: `Si prega di compilare tutti i campi obbligatori al fine di procedere con l'invio della segnalazione`,
  },
  email_confirmed: {
    id: 'email_confirmed',
    defaultMessage: `La segnalazione è stata inviata con successo`,
  },
  close_issue: {
    id: 'close_issue',
    defaultMessage: `Chiudi segnalazione`,
  },
});

/**
 * IssueStepperNav view component class.
 * @function IssueStepperNav
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const IssueStepperNav = ({
  content,
  currentState,
  setCurrentState,
  images,
  address,
  issueType,
  title,
  details,
  firstName,
  lastName,
  phone,
  email,
  fiscalNumber,
  validationError,
  setValidationError,
}) => {
  const intl = useIntl();
  const [isOpen, toggleModal] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendError, setSendError] = useState(``);
  const emailIssueState = useSelector((state) => state.emailIssue);
  const dispatch = useDispatch();
  const linkTerms = 'https://pordenone.tecnotools.it/it/informativa-privacy';

  const validateFields = () => {
    const requiredFields = [
      { value: address, name: 'Address' },
      { value: issueType, name: 'Issue Type' },
      { value: title, name: 'Title' },
      { value: firstName, name: 'First Name' },
      { value: lastName, name: 'Last Name' },
      { value: email, name: 'Email' },
    ];

    const emptyFields = requiredFields.filter(
      (field) => !field.value || field.value.trim() === '',
    );

    if (emptyFields.length > 0) {
      setValidationError(intl.formatMessage(messages.validateFields));
      return false;
    }

    setValidationError('');
    return true;
  };

  // GO BACK
  const handleBackClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentState(currentState - 1);
  };

  // ADVANCE
  const handleNextClick = () => {
    if (validateFields()) {
      if (currentState === 3) {
        toggleModal((isOpen) => !isOpen);
      } else {
        window.scrollTo({ top: 550, behavior: 'smooth' });
        setCurrentState(currentState + 1);
      }
    }
  };

  //CONFIRM FROM MODAL
  const handleConfirm = async () => {
    const emailData = {
      email: email,
      firstname: firstName,
      lastname: lastName,
      issue_type: issueType,
      address: address,
      phone: phone,
      title: title,
      details: details,
      fiscalNumber: fiscalNumber,
      images: [],
    };

    const sendEmail = () => {
      // todo dispach
      const baseURL = expandToBackendURL(``);
      setIsLoading(true);
      try {
        const response = dispatch(sendEmailIssue(baseURL, emailData));
        if (emailIssueState?.result?.status === 'ok') {
          setIsLoading(false);
          setEmailStatus(true);
          setSendError('');
        } else if (emailIssueState?.error) {
          setIsLoading(false);
          setSendError(emailIssueState.error);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error sending email:', error);
        setSendError(error);
      }
    };

    if (images.length) {
      let count = 0;
      images.forEach((image, index) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          emailData?.images?.push({
            name: image.name,
            data: reader.result,
            contentType: image.type,
          });
          count += 1;
          if (count === images.length) {
            sendEmail();
          }
        };
        reader.readAsDataURL(image);
      });
    } else {
      sendEmail();
    }
  };

  useEffect(() => {
    if (emailIssueState?.result?.status === 'ok') {
      setIsLoading(false);
      setEmailStatus(true);
    }
    if (emailIssueState?.error) {
      setIsLoading(false);
      setSendError(emailIssueState.error);
    }
  }, [emailIssueState]);

  if (!currentState) {
    setCurrentState(1);
  }

  return (
    <>
      <StepperContainer>
        <div className="cmp-nav-steps">
          {validationError && (
            <div className="text-center border border-danger col-12 p-2 mt-2">
              {validationError}
            </div>
          )}
          <StepperNav>
            {/* BUTTON GO BACK*/}
            <Button
              type="button"
              className="btn btn-outline-primary bg-white btn-sm steppers-btn-save d-none d-lg-block btn-back-step"
              data-element="btn-back"
              onClick={handleBackClick}
              disabled={currentState < 3}
            >
              <span className="text-button-sm t-primary">
                {intl.formatMessage(messages.go_back)}
              </span>
            </Button>
            {/* BUTTON ADVANCE*/}
            <Button
              type="button"
              className="btn btn-primary btn-sm stepper-btn-next d-flex justify-content-center align-items-center"
              style={{ height: '48px' }}
              data-bs-toggle="modal"
              data-bs-target="#modal-save-1"
              data-focus-mouse="false"
              data-element="advance"
              onClick={handleNextClick}
            >
              <span className="text-button-sm text-white flex-fill">
                {intl.formatMessage(messages.advance)}
              </span>
              <Icon icon="it-chevron-right" title="Avanti" />
            </Button>
          </StepperNav>
        </div>
      </StepperContainer>
      <div style={{ marginBottom: '2rem' }}>
        <Modal
          isOpen={isOpen}
          labelledBy={`widget-modal-${content.title}`}
          size="md"
          className="col-12"
        >
          <ModalHeader
            id={`widget-modal-header-${content.title}`}
            className="position-relative"
          >
            <strong>
              <h5>{intl.formatMessage(messages.terms)}</h5>
            </strong>
            <Button
              className="position-absolute end-0 top-0 m-2"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Chiudi finestra modale"
              onClick={() => {
                toggleModal(!isOpen);
              }}
            >
              <Icon
                className=""
                color="primary"
                icon="it-close-big"
                size=""
                title="close"
              />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Row className="px-2">
              {intl.formatMessage(messages.confirm_text)}
            </Row>
            <Row>
              <a href={linkTerms} target="_blank" rel="noreferrer">
                {intl.formatMessage(messages.readTerms)}
              </a>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-5 d-flex justify-content-center">
            {isLoading ? (
              <Spinner className="pb-4" active small />
            ) : (
              <>
                {/* CONFIRM */}
                <Button
                  className={cx('col-12 btn btn-primary text-white', {
                    'd-none': emailStatus || sendError,
                  })}
                  onClick={handleConfirm}
                  disabled={emailStatus}
                >
                  {intl.formatMessage(messages.confirm)}
                </Button>
                {/* CANCEL */}
                <Button
                  className={cx('col-12 btn btn-outline-primary bg-white', {
                    'd-none': emailStatus || sendError,
                  })}
                  onClick={() => {
                    toggleModal((isOpen) => !isOpen);
                  }}
                >
                  {intl.formatMessage(messages.cancel)}
                </Button>
              </>
            )}
            {sendError && (
              <div className="col-10 text-danger border border-danger p-3 mb-4">
                {sendError}
              </div>
            )}
            {emailStatus && (
              <>
                <div className="col-10 text-success border border-success p-3 mb-4">
                  {intl.formatMessage(messages.email_confirmed)}
                </div>
                {/* CLOSE */}
                <Button
                  className={cx('col-10 btn btn-outline-primary bg-white', {
                    'd-none': !emailStatus,
                  })}
                  onClick={() => {
                    window.location.href = '/';
                  }}
                >
                  {intl.formatMessage(messages.close_issue)}
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IssueStepperNav.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default IssueStepperNav;
