/**
 * Gates proxy.
 * @module proxies/gates
 *
 * Proxy for gates management.
 *
 */
import { makeGetRequest, makePostRequest } from './utils';

export const getGateResponse = async (gateName: string, method: string, parameters: object): Promise<object | null> => {
  const url = `etl/gate/private/${gateName}`;

  return makeGetRequest(url, {
    params: parameters,
  });
};

export const makePagoPARequest = async (iuv: string, process_name: string, process_id: string, process_attr: string): Promise<object | null> => {
  const url = `etl/gate/private/PagoPAAvviaPagamento`;
  return makeGetRequest(url, {
    params: {
      iuv: iuv,
      process_name: process_name,
      process_id: process_id,
      process_attr: process_attr,
    },
  });
};

export const updatePayment = async (processid: string | number): Promise<object | null> => {
  const url = `etl/gate/private/UtilsCheckSchoolTrasportPayments`;
  return makeGetRequest(url, {
    params: {
      process_id: processid,
    },
  });
};
