/**
 * ReservationConfirmation view component.
 * @module components/View/ReservationConfirmation
 */
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Icon, Button } from 'design-react-kit';
import { expandToBackendURL } from '@plone/volto/helpers';
import { useDispatch } from 'react-redux';
import { deleteBooking } from 'comune-pordenone-plone-theme/actions';
import { useState, useEffect } from 'react';

const messages = defineMessages({
  descriptionAgenda: {
    id: 'descriptionAgenda',
    defaultMessage: 'Descrizione agenda',
  },
  needed: {
    id: 'needed',
    defaultMessage: 'Cosa serve',
  },
  address: {
    id: 'address',
    defaultMessage: 'Indirizzo',
  },
  AddCalendar: {
    id: 'AddCalendar',
    defaultMessage: 'Aggiungi al tuo calendario',
  },
  outlook: {
    id: 'outlook',
    defaultMessage: 'Outlook',
  },
  GoogleCalendar: {
    id: 'GoogleCalendar',
    defaultMessage: 'Google Calendar',
  },
  iCloudCalendar: {
    id: 'iCloudCalendar',
    defaultMessage: 'iCloud Calendar',
  },
  moveReservation: {
    id: 'moveReservation',
    defaultMessage: 'Sposta la prenotazione',
  },
  deleteReservation: {
    id: 'deleteReservation',
    defaultMessage: 'Elimina la prenotazione',
  },
  bookingConfirm: {
    id: 'bookingConfirm',
    defaultMessage: "Conferma dell'appuntamento",
  },
  detailsReservation: {
    id: 'detailsReservation',
    defaultMessage: 'Dettagli prenotazione',
  },
  time: {
    id: 'time',
    defaultMessage: 'Orario',
  },
  duration: {
    id: 'duration',
    defaultMessage: 'Durata appuntamento',
  },
  reservationReason: {
    id: 'reservationReason',
    defaultMessage: "Motivo dell'appuntamento",
  },
  minutes: {
    id: 'minutes',
    defaultMessage: 'minuti',
  },
});
/**
 * ReservationConfirmation view component class.
 * @function ReservationConfirmation
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ReservationConfirmation = ({
  content,
  currentState,
  selectedAppointment,
  selectedType,
  moreDetails,
  createResponse,
  formatAppointmentDate,
}) => {
  const intl = useIntl();
  const startdt = new Date(selectedAppointment);
  const enddt = new Date(
    startdt.getTime() + (selectedType?.duration || 0) * 60000,
  );
  const subject = selectedType?.name;
  const body = moreDetails;
  const dispatch = useDispatch();
  const [duration, setDuration] = useState();
  const baseURL = expandToBackendURL(content?.[`@id`]);
  let startParam = '';
  let endParam = '';

  // Format dates for URL parameters
  const formatDateForUrl = (date) => {
    return date.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
  };

  if (selectedAppointment) {
    startParam = formatDateForUrl(startdt);
    endParam = formatDateForUrl(enddt);
  }

  // define calendars path
  const outlookURL = `https://outlook.office.com/calendar/action/compose?subject=${encodeURIComponent(
    subject,
  )}&startdt=${encodeURIComponent(startdt)}&enddt=${encodeURIComponent(
    enddt,
  )}&body=${encodeURIComponent(body)}`;
  const googleURL = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
    subject,
  )}&dates=${startParam}/${endParam}&details=${encodeURIComponent(body)}`;

  const icloudURL = `https://www.icloud.com/calendar/?start=${startdt}&end=${enddt}&title=${encodeURIComponent(
    subject,
  )}&notes=${encodeURIComponent(body)}`;

  // delete reservation
  const deleteReservation = async () => {
    try {
      const response = await dispatch(
        deleteBooking(baseURL, createResponse?.UID),
      );
    } catch (error) {
      console.error('Error deleting reservation:', error);
    }
  };

  // set appointment duration
  useEffect(() => {
    if (selectedType && typeof selectedType === 'object') {
      setDuration(selectedType.duration);
    } else {
      setDuration(undefined);
    }
  }, [selectedType]);

  return currentState === 5 ? (
    <RichTextSection
      tag_id={intl.formatMessage(messages.bookingConfirm)}
      field="text"
      title={intl.formatMessage(messages.bookingConfirm)}
      show_title={false}
      lighthouseId={'details-reservation'}
    >
      <RichTextSection data={content?.descriptionAgenda} show_title={false} />
      {/* RESERVATION DETAILS */}
      <RichTextSection
        tag_id={'title-details-reservation'}
        field="text"
        title={intl.formatMessage(messages.detailsReservation)}
        show_title={true}
      >
        <div>
          <h6>{intl.formatMessage(messages.reservationReason)}</h6>
          <p>{selectedType?.name}</p>
        </div>
        <div>
          <h6>{intl.formatMessage(messages.time)}</h6>
          <p>{formatAppointmentDate(selectedAppointment)}</p>
        </div>
        <div>
          <h6>{intl.formatMessage(messages.duration)}</h6>
          <p>
            {duration} {intl.formatMessage(messages.minutes)}
          </p>
        </div>
      </RichTextSection>
      {/* DELETE RESERVATION */}
      <RichTextSection
        tag_id={'title-delete-reservation'}
        field="text"
        title={intl.formatMessage(messages.deleteReservation)}
        show_title={true}
      >
        <p>
          <Button
            className="list-item-title-icon-wrapper"
            onClick={deleteReservation}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              padding: 0,
            }}
          >
            <Icon icon={'it-close-big'} padding={true} />
            <span className="list-item-title title-small-semi-bold fw-normal link-primary">
              {intl.formatMessage(messages.deleteReservation)}
            </span>
          </Button>
        </p>
      </RichTextSection>
      {/* AGGIUNGI AL TUO CALENDARIO */}
      <RichTextSection
        tag_id={'title-AddCalendar'}
        field="text"
        title={intl.formatMessage(messages.AddCalendar)}
        show_title={true}
      >
        <p className="">
          <a
            className="list-item icon-left t-primary title-small-semi-bold"
            href={outlookURL}
            target="_blank"
            rel="noreferrer"
          >
            <span className="list-item-title-icon-wrapper">
              <Icon icon={'it-calendar'} padding={true} />
              <span className="list-item-title title-small-semi-bold fw-normal link">
                {intl.formatMessage(messages.outlook)}
              </span>
            </span>
          </a>
        </p>
        <p className="">
          <a
            className="list-item icon-left t-primary title-small-semi-bold"
            href={googleURL}
            target="_blank"
            rel="noreferrer"
          >
            <span className="list-item-title-icon-wrapper">
              <Icon icon={'it-calendar'} padding={true} />
              <span className="list-item-title title-small-semi-bold fw-normal">
                {intl.formatMessage(messages.GoogleCalendar)}
              </span>
            </span>
          </a>
        </p>
        <p className="">
          <a
            className="list-item icon-left t-primary title-small-semi-bold"
            href={icloudURL}
            target="_blank"
            rel="noreferrer"
          >
            <span className="list-item-title-icon-wrapper">
              <Icon icon={'it-calendar'} padding={true} />
              <span className="list-item-title title-small-semi-bold fw-normal">
                {intl.formatMessage(messages.iCloudCalendar)}
              </span>
            </span>
          </a>
        </p>
      </RichTextSection>
    </RichTextSection>
  ) : (
    <></>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ReservationConfirmation.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default ReservationConfirmation;
