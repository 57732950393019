import { Card, CardBody, CardTitle } from 'design-react-kit';

import PropTypes from 'prop-types';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';

const PNOfficeSubOffices = ({ content }) => {
  if (content?.offices?.length > 0) {
    return (
      <RichTextSection
        tag_id={'title-office-organization-offices'}
        title="Articolazione"
        show_title={true}
      >
        {content.offices?.map((office, index) => {
          return (
            <Card
              key={index}
              className="card card-teaser shadow p-4 mt-3 rounded attachment"
              noWrapper={true}
              tag="div"
            >
              <CardBody>
                <CardTitle tag="h5">
                  <a href={flattenToAppURL(office['@id'])}>{office.title}</a>
                </CardTitle>
                {office?.description && <p>{office.description}</p>}
              </CardBody>
            </Card>
          );
        })}
      </RichTextSection>
    );
  } else {
    return <></>;
  }
};

PNOfficeSubOffices.propTypes = {
  offices: PropTypes.array,
};

export default PNOfficeSubOffices;
