/**
 * View Accordion block.
 * @module components/ItaliaTheme/Blocks/Accordion/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardText } from 'design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  services: {
    id: 'Services',
    defaultMessage: 'Servizi',
  },
});

/**
 * View Accordion block class.
 * @class View
 * @extends Component
 */
const PNOfficeServices = ({ content, block }) => {
  const intl = useIntl();

  let services = [];
  content?.items.forEach((item) => {
    if (item.type_title === 'PNService') {
      services.push(item);
    }
  });

  return services?.length > 0 ? (
    <RichTextSection
      tag_id="office-services"
      field="text"
      title={intl.formatMessage(messages.services)}
      show_title={true}
    >
      {/* SERVICES */}
      {services.map((service) => (
        <Card
          className="card-bg card-teaser rounded my-3 p-3 col-5"
          noWrapper={true}
          space
          tag="div"
        >
          <CardBody className="">
            {/* SET HERE CATERGORY 
            <h6>MOBILITÀ E TRASPORTI </h6>
            */}
            <a href={flattenToAppURL(service.url)}>
              <b>{service.title}</b>
            </a>
            <CardText tag="p">{service.description}</CardText>
          </CardBody>
        </Card>
      ))}
    </RichTextSection>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNOfficeServices.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PNOfficeServices;
