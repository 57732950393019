/**
 * Form widgets Payment notice widget module.
 * @module formwidgets/paymentnotice
 *
 */
import { CardModel, ProcessInstanceModel, WidgetModel } from '../models';
import { useEffect, useState } from 'react';

import { Spinner } from 'design-react-kit';
import { getGateResponse } from '../proxies';

export const FileWidget = ({ widget, context }: { widget: WidgetModel; context: CardModel | ProcessInstanceModel | object }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [iframData, setIframeData] = useState('');
  const [hasErrors, setHasErrors] = useState(false);

  const _arrayBufferToBase64 = (buffer: Number[]) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    getGateResponse(widget.Endpoint, 'GET', {}).then((response: { data?: object[]; meta?: object }) => {
      if (response.success) {
          const iframedata = 'data:application/pdf;base64,' + _arrayBufferToBase64(response?.data);
          setIframeData(iframedata);
      } else {
        setHasErrors(true);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <div className="align-middle" hidden={!isLoading}>
        <p className="mb-3">Caricamento in corso</p>
        <Spinner active double />
      </div>
      <iframe
        hidden={isLoading || hasErrors}
        src={iframData}
        data-ref="iframeEl"
        width="100%"
        height={window.innerHeight * 0.8}
        frameborder="0"
      ></iframe>
      <p hidden={isLoading || !hasErrors}>Si sono verificati degli errori nel recupero del file.</p>
    </>
  );
};
