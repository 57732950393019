import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Select } from 'design-react-kit';

const messages = defineMessages({
  label_dependings: {
    id: 'label_dependings',
    defaultMessage: 'Dipende da',
  },
  morning: {
    id: 'morning',
    defaultMessage: 'mattina',
  },
  afternoon: {
    id: 'afternoon',
    defaultMessage: 'pomeriggio',
  },
  from_time: {
    id: 'from_time',
    defaultMessage: 'dalle',
  },
  to_time: {
    id: 'to_time',
    defaultMessage: 'alle',
  },
  monday: {
    id: 'monday',
    defaultMessage: 'Lunedì',
  },
  tuesday: {
    id: 'tuesday',
    defaultMessage: 'Martedì',
  },
  wednesday: {
    id: 'wednesday',
    defaultMessage: 'Mercoledì',
  },
  thursday: {
    id: 'thursday',
    defaultMessage: 'Giovedì',
  },
  friday: {
    id: 'friday',
    defaultMessage: 'Venerdì',
  },
  saturday: {
    id: 'saturday',
    defaultMessage: 'Sabato',
  },
  day: {
    id: 'day',
    defaultMessage: 'Giorno',
  },
});

const HOURS = [
  '--',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
];

const MINUTES = [
  '--',
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];

const DEFAULT_DAYS = [
  { title: 'monday', abbr: 'mon' },
  { title: 'tuesday', abbr: 'tue' },
  { title: 'wednesday', abbr: 'wed' },
  { title: 'thursday', abbr: 'thu' },
  { title: 'friday', abbr: 'fri' },
  { title: 'saturday', abbr: 'sat' },
];

const TimeSelect = ({ id, name, options, value, onChange }) => (
  <select
    id={id}
    name={name}
    className="border rounded p-0 text-sm"
    value={value}
    onChange={onChange}
  >
    {options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))}
  </select>
);

const OfficeTimeWidget = (props) => {
  const { id, name, value, onChange } = props;
  const intl = useIntl();

  const handleTimeChange = (dayIndex, slotIndex, newValue, isHour) => {
    const newTimeValues =
      value && value.length > 0
        ? value
        : Array(DEFAULT_DAYS.length * 4).fill('--:--');

    // Calculate correct index in array
    const index = dayIndex * 4 + slotIndex;

    // Join with previous minute or hour if it exists
    const currentVal = newTimeValues[index] || '--:--';
    const [hour, minute] = currentVal.split(':');

    // Conditionally update either hour or minute
    const updatedTime = isHour
      ? `${newValue}:${minute || '00'}`
      : `${hour || '00'}:${newValue}`;

    newTimeValues[index] = updatedTime;
    onChange?.(id, newTimeValues);
  };

  const timeValue =
    value && value.length > 0
      ? value
      : Array(DEFAULT_DAYS.length * 4).fill('--:--');

  return (
    <div
      className="inline field help text field-wrapper-time"
      style={{
        'padding-left': '1vw',
      }}
    >
      <table className="w-full border-collapse">
        <thead>
          <th scope="col" style={{ 'padding-bottom': '1vh' }}>
            {intl.formatMessage(messages.day)}
          </th>
          <th colspan="9" scope="colgroup" style={{ 'padding-bottom': '1vh' }}>
            {intl.formatMessage(messages.morning)}
          </th>
          <th colspan="9" scope="colgroup" style={{ 'padding-bottom': '1vh' }}>
            {intl.formatMessage(messages.afternoon)}
          </th>
        </thead>
        <tbody>
          {DEFAULT_DAYS?.map((day, dayIndex) => (
            <>
              <tr key={`${day.abbr}-id`} className="stretched row">
                <td rowSpan="2" className="px-4">
                  <strong>{intl.formatMessage(messages[day.title])}</strong>
                </td>
                <td className="px-4">
                  {intl.formatMessage(messages.from_time)}
                </td>
                <td className="px-4">
                  <TimeSelect
                    id={`${day.abbr}Msh`}
                    name={name}
                    options={HOURS}
                    value={timeValue[dayIndex * 4]?.split(':')[0] || '--:--'}
                    onChange={(e) =>
                      handleTimeChange(dayIndex, 0, e.target.value, true)
                    }
                  />
                </td>
                <td className="px-2">:</td>
                <td className="px-4">
                  <TimeSelect
                    id={`${day.abbr}Msm`}
                    name={name}
                    options={MINUTES}
                    value={timeValue[dayIndex * 4]?.slice(-2) || '--:--'}
                    onChange={(e) =>
                      handleTimeChange(dayIndex, 0, e.target.value, false)
                    }
                  />
                </td>
                <td className="px-4">{intl.formatMessage(messages.to_time)}</td>
                <td className="px-4">
                  <TimeSelect
                    id={`${day.abbr}Meh`}
                    name={name}
                    options={HOURS}
                    value={timeValue[dayIndex * 4 + 1]?.slice(0, 2) || '--:--'}
                    onChange={(e) =>
                      handleTimeChange(dayIndex, 1, e.target.value, true)
                    }
                  />
                </td>
                <td className="px-2">:</td>
                <td className="px-4">
                  <TimeSelect
                    id={`${day.abbr}Mem`}
                    name={name}
                    options={MINUTES}
                    value={timeValue[dayIndex * 4 + 1]?.slice(-2) || '--:--'}
                    onChange={(e) =>
                      handleTimeChange(dayIndex, 1, e.target.value, false)
                    }
                  />
                </td>
                <td className="px-4">
                  {intl.formatMessage(messages.from_time)}
                </td>
                <td className="px-4">
                  <TimeSelect
                    id={`${day.abbr}Ash`}
                    name={name}
                    options={HOURS}
                    value={timeValue[dayIndex * 4 + 2]?.slice(0, 2) || '--:--'}
                    onChange={(e) =>
                      handleTimeChange(dayIndex, 2, e.target.value, true)
                    }
                  />
                </td>
                <td className="px-2">:</td>
                <td className="px-4">
                  <TimeSelect
                    id={`${day.abbr}Asm`}
                    name={name}
                    options={MINUTES}
                    value={timeValue[dayIndex * 4 + 2]?.slice(-2) || '--:--'}
                    onChange={(e) =>
                      handleTimeChange(dayIndex, 2, e.target.value, false)
                    }
                  />
                </td>
                <td className="px-4">{intl.formatMessage(messages.to_time)}</td>
                <td className="px-4">
                  <TimeSelect
                    id={`${day.abbr}Aeh`}
                    name={name}
                    options={HOURS}
                    value={timeValue[dayIndex * 4 + 3]?.slice(0, 2) || '--:--'}
                    onChange={(e) =>
                      handleTimeChange(dayIndex, 3, e.target.value, true)
                    }
                  />
                </td>
                <td className="px-2">:</td>
                <td className="px-4">
                  <TimeSelect
                    id={`${day.abbr}Aem`}
                    name={name}
                    options={MINUTES}
                    value={timeValue[dayIndex * 4 + 3]?.slice(-2) || '--:--'}
                    onChange={(e) =>
                      handleTimeChange(dayIndex, 3, e.target.value, false)
                    }
                  />
                </td>
              </tr>
              {dayIndex < DEFAULT_DAYS.length - 1 && (
                <tr>
                  <td colSpan="10" className="h-4"></td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

OfficeTimeWidget.propTypes = {
  content: PropTypes.shape({
    days: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        abbr: PropTypes.string,
      }),
    ),
  }),
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default OfficeTimeWidget;
