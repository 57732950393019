/**
 * Privacy component.
 * @module components/Features/Privacy/Privacy
 *
 * Page which displays privacy page.
 *
 */
import { Row, StepperContainer } from 'design-react-kit';
import { Button } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { ProcessStateStepper } from '@tecnoteca/volto-cmdbuild/utilities/processstatestepper';
import { useProcessModel } from '@tecnoteca/volto-cmdbuild/modelsuses';
import { AttributeModel, ProcessInstanceModel, ProcessModel } from '@tecnoteca/volto-cmdbuild/models';

const messages = defineMessages({
  segnalazione_disservizio: {
    id: 'segnalazione_disservizio',
    defaultMessage: 'Segnalazione disservizio',
  },
  informativa_privacy: {
    id: 'informativa_privacy',
    defaultMessage: 'Informativa sulla privacy',
  },

  /** TODO:
   * Set dinamic text taking it from the control panel
   */
  condizioni: {
    id: 'condizioni',
    defaultMessage: `
    Il Comune di Pordenone gestisce i dati personali forniti e
    liberamente comunicati sulla base dell’articolo 13 del Regolamento
    (UE) 2016/679 General data protection regulation (Gdpr) e degli
    articoli 13 e successive modifiche e integrazione del decreto
    legislativo (di seguito d.lgs) 267/2000 (Testo unico enti locali).`,
  },
  trattamentoDati: {
    id: 'trattamentoDati',
    defaultMessage:
      'Per i dettagli sul trattamento dei dati personali consulta l’',
  },
  informativa: {
    id: 'informativa',
    defaultMessage: 'informativa sulla privacy.',
  },
  testo_privacy_check: {
    id: 'testo_privacy_check',
    defaultMessage: 'Ho letto e compreso l’informativa sulla privacy.',
  },
  testo_error_check: {
    id: 'testo_error_check',
    defaultMessage: `* Prima di procedere accettare l'informativa sulla privacy.`,
  },
  avanti: {
    id: 'avanti',
    defaultMessage: 'Avanti',
  },
});
const Privacy = (props) => {
  const { getProcess, getProcessAttributes, getProcessInstance, getProcessActivity, advanceProcessInstance, saveProcessInstance } = useProcessModel();
  const [showError, setShowError] = useState(false);
  const [process, setProcess] = useState<ProcessModel | null>();
  const [processInstance, setProcessInstance] = useState<ProcessInstanceModel | null>();
  const [processAttributes, setProcessAttributes] = useState<AttributeModel[] | object[] | null>();
  const content = props.content;
  const processName = props.processName;
  const instanceId = props.instanceId;
  const activityId = props.activityId;
  const intl = useIntl();

  useEffect(() => {
    // load process info
    getProcess(processName).then((response) => {
      setProcess(response);
    });

    // load attributes
    getProcessAttributes(processName).then((response) => {
      setProcessAttributes(response);
    });

    let activityRequestParams: [string | number] = [processName];
    if (instanceId && activityId) {
      // load process instance
      getProcessInstance(processName, instanceId).then((data) => {
        setProcessInstance(data);
      });
      // add instance id and activity id in activity request parameters
      activityRequestParams.push(instanceId, activityId);
    } else {
      // set empty process instance
      setProcessInstance({
        _type: processName,
      });
    }
  }, []);

  const confirmAction = () => {
    let checkbox = document.getElementById('privacy_check').checked;
    if (checkbox === true) {
      props.confirmAction(true);
    } else {
      setShowError(true);
    }
  };
  return (
    <>
      <StepperContainer>
        <ProcessStateStepper attributes={processAttributes} context={processInstance} />
        <Row className="justify-content-center">
          <div className="col-12 col-lg-8 pb-40 pb-lg-80">
            <h4 className="text-primary">
              {intl.formatMessage(messages.informativa_privacy)}
            </h4>
            <p className="text-paragraph mb-lg-4">
              {intl.formatMessage(messages.condizioni)}
            </p>
            <p className="text-paragraph mb-0">
              {intl.formatMessage(messages.trattamentoDati)}
              <a
                href="https://www.comune.pordenone.it/it/info/privacy"
                className="t-primary"
              >
                {intl.formatMessage(messages.informativa)}
              </a>
            </p>
            <div className="form-check mt-4 mb-3 mt-md-40 mb-lg-40">
              <div className="checkbox-body d-flex align-items-center">
                <input
                  type="checkbox"
                  id="privacy_check"
                  name="privacy-field"
                  value="privacy-field"
                />
                <label
                  className="title-small-semi-bold pt-1"
                  htmlFor="privacy_check"
                >
                  {intl.formatMessage(messages.testo_privacy_check)}
                </label>
              </div>
              <label
                className={`title-small-semi-bold danger text-primary fs-6 ${
                  showError ? '' : 'invisible'
                }`}
                id="checkErrorText"
              >
                {intl.formatMessage(messages.testo_error_check)}
              </label>
            </div>
          </div>
        </Row>
        <Row className="justify-content-center">
          <div className="col-12 col-lg-8 pb-40 pb-lg-80">
            <div className="bg-grey-card shadow-contacts mb-4">
              <Row>
                <div className="p-contacts justify-content-start">
                  <Button
                    type="button"
                    className="btn btn-primary mobile-full col-12 col-lg-6"
                    onClick={confirmAction}
                  >
                    <span className="">
                      {intl.formatMessage(messages.avanti)}
                    </span>
                  </Button>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </StepperContainer>
    </>
  );
};

export default Privacy;
