/**
 * ReservationDetails view component.
 * @module components/View/ReservationDetails
 */

import PropTypes from 'prop-types';
import {
  Card,
  StepperContainer,
  StepperContent,
  CardBody,
  Input,
} from 'design-react-kit';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { useSelector } from 'react-redux';

const messages = defineMessages({
  requester: {
    id: 'requester',
    defaultMessage: 'Richiedente',
  },
  requesterDescription: {
    id: 'requesterDescription',
    defaultMessage: 'Informazioni su di te',
  },
  details: {
    id: 'details',
    defaultMessage: 'Dettagli',
  },
  detailsDescription: {
    id: 'detailsDescription',
    defaultMessage: 'Aggiungi ulteriori dettagli',
  },
  firstName_required: {
    id: 'firstName_required',
    defaultMessage: 'Nome *',
  },
  firstNameDescription: {
    id: 'firstNameDescription',
    defaultMessage: 'Inserisci il tuo nome',
  },
  lastName_required: {
    id: 'lastName_required',
    defaultMessage: 'Cognome *',
  },
  lastNameDescription: {
    id: 'lastNameDescription',
    defaultMessage: 'Inserisci il tuo cognome',
  },
  email_required: {
    id: 'email_required',
    defaultMessage: 'E-mail *',
  },
  emailDescription: {
    id: 'emailDescription',
    defaultMessage: 'Inserisci la tua email',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Telefono',
  },
  phoneDescription: {
    id: 'phoneDescription',
    defaultMessage: 'Inserisci il tuo telefono',
  },
  showEverything: {
    id: 'showEverything',
    defaultMessage: 'Mostra tutto',
  },
  contacts: {
    id: 'contacts',
    defaultMessage: 'Contatti',
  },
  fiscalNumber: {
    id: 'fiscalNumber',
    defaultMessage: 'Codice fiscale',
  },
  required_field: {
    id: 'required_field',
    defaultMessage: 'Questo campo è obbligatorio',
  },
  invalid_email: {
    id: 'invalid_email',
    defaultMessage: `L'indirizzo email inserito non è valido`,
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'Nome',
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Cognome',
  },
  email: {
    id: 'email',
    defaultMessage: 'E-mail',
  },
});

/**
 * ReservationDetails view component class.
 * @function ReservationDetails
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ReservationDetails = ({
  currentState,
  requesterData,
  setRequesterData,
  formError,
  setFormError,
  showFormError,
}) => {
  const intl = useIntl();
  const userSession = useSelector((state) => state.portalUser);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  /**
   * Handles changes in the requester data inputs.
   * Updates the corresponding state and requesterData object.
   * @param {Event} event - The input change event.
   */
  const handleRequesterDataChange = (event) => {
    const { name, value } = event?.target;
    name === intl.formatMessage(messages.firstName) && setFirstName(value);
    name === intl.formatMessage(messages.lastName) && setLastName(value);
    name === intl.formatMessage(messages.email) && setEmail(value);

    setRequesterData((prevData) => {
      const updatedField = Object.keys(messages).find(
        (key) => intl.formatMessage(messages[key]) === name,
      );
      if (updatedField) {
        return {
          ...prevData,
          [intl.formatMessage(messages[updatedField])]: value,
        };
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  // sets requester data checking user authentication
  useEffect(() => {
    if (userSession.isAuthenticated) {
      setRequesterData({
        [intl.formatMessage(messages.firstName)]: userSession?.user?.first_name,
        [intl.formatMessage(messages.lastName)]: userSession?.user?.last_name,
        [intl.formatMessage(messages.fiscalNumber)]:
          userSession?.user?.fiscal_number,
      });
    }
  }, [userSession.isAuthenticated]);

  /**
   * Validates if the given email is in a proper format.
   * @param {string} email - The email address to validate.
   * @returns {boolean} True if the email format is valid, otherwise false.
   */
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };

  /**
   * Validates the form inputs when the current state is 3.
   * Sets form error states based on the validity of the inputs.
   * This useEffect runs whenever relevant dependencies change.
   */
  useEffect(() => {
    if (currentState === 3) {
      setFormError(true);
      if (
        (!firstName && userSession.isAuthenticated === false) ||
        (!lastName && userSession.isAuthenticated === false) ||
        !email
      ) {
        if (!firstName && userSession.isAuthenticated === false) {
          setFirstNameError(true);
        }
        if (!lastName && userSession.isAuthenticated === false) {
          setLastNameError(true);
        }
        if (!email) {
          setEmailError(true);
          if (validateEmail(email)) {
            setEmailError(false);
          } else {
            setEmailError(true);
          }
        }
      } else {
        setFormError(false);
      }
      if (email) {
        if (validateEmail(email)) {
          setEmailError(false);
        } else {
          setEmailError(true);
          setFormError(true);
        }
      }
      if (userSession.isAuthenticated === false) {
        if (firstName) {
          setFirstNameError(false);
        }
        if (lastName) {
          setLastNameError(false);
        }
      }
    }
  }, [
    currentState,
    formError,
    firstName,
    lastName,
    email,
    requesterData,
    userSession.isAuthenticated,
  ]);

  return currentState === 3 ? (
    <>
      <RichTextSection
        tag_id={intl.formatMessage(messages.requester)}
        field="text"
        title={intl.formatMessage(messages.requester)}
        show_title={false}
      >
        <StepperContainer>
          <StepperContent
            className="steppers-content border-0 h-100"
            aria-live="polite"
          >
            <div className="col-10 h-100">
              <section className="it-page-section h-100">
                <h2 className="mt-4">
                  {userSession.isAuthenticated === false
                    ? intl.formatMessage(messages.requester)
                    : userSession?.user?.first_name +
                      ' ' +
                      userSession?.user?.last_name}
                </h2>
                <Card className="card p-big my-4 h-100">
                  <CardBody className="mt-4">
                    {userSession.isAuthenticated === true ? (
                      <Input
                        type="text"
                        label={intl.formatMessage(messages.fiscalNumber)}
                        value={userSession?.user?.fiscal_number}
                      />
                    ) : (
                      <>
                        <Input
                          type="text"
                          id="firstName-input"
                          name={intl.formatMessage(messages.firstName)}
                          label={intl.formatMessage(
                            messages.firstName_required,
                          )}
                          infoText={intl.formatMessage(
                            messages.firstNameDescription,
                          )}
                          onChange={handleRequesterDataChange}
                          valid={
                            showFormError === null ? null : !firstNameError
                          }
                          validationText={
                            firstNameError && showFormError
                              ? intl.formatMessage(messages.required_field)
                              : ''
                          }
                          value={firstName}
                        />
                        <Input
                          type="text"
                          id="lastName-input"
                          name={intl.formatMessage(messages.lastName)}
                          label={intl.formatMessage(messages.lastName_required)}
                          infoText={intl.formatMessage(
                            messages.lastNameDescription,
                          )}
                          onChange={handleRequesterDataChange}
                          valid={showFormError === null ? null : !lastNameError}
                          validationText={
                            lastNameError && showFormError
                              ? intl.formatMessage(messages.required_field)
                              : ''
                          }
                          value={lastName}
                        />
                      </>
                    )}
                    <Input
                      type="email"
                      id="email-input"
                      name={intl.formatMessage(messages.email)}
                      label={intl.formatMessage(messages.email_required)}
                      infoText={intl.formatMessage(messages.emailDescription)}
                      onChange={handleRequesterDataChange}
                      valid={showFormError === null ? null : !emailError}
                      validationText={
                        showFormError && emailError
                          ? !email
                            ? intl.formatMessage(messages.required_field)
                            : intl.formatMessage(messages.invalid_email)
                          : ''
                      }
                      value={email}
                    />
                    <Input
                      type="text"
                      id="phone-input"
                      name={intl.formatMessage(messages.phone)}
                      label={intl.formatMessage(messages.phone)}
                      infoText={intl.formatMessage(messages.phoneDescription)}
                      onChange={handleRequesterDataChange}
                    />
                  </CardBody>
                </Card>
              </section>
            </div>
          </StepperContent>
        </StepperContainer>
      </RichTextSection>
    </>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ReservationDetails.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default ReservationDetails;
