import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, CardTitle } from 'design-react-kit';
import {
  RichTextSection,
  ContactLink,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  contacts: {
    id: 'Contacts',
    defaultMessage: 'Contatti',
  },
  file: {
    id: 'file',
    defaultMessage: 'File',
  },
  person: {
    id: 'person',
    defaultMessage: 'Peronale',
  },
  role: {
    id: 'role',
    defaultMessage: 'Ruolo',
  },
  phones: {
    id: 'phones',
    defaultMessage: 'Telefono',
  },
  emails: {
    id: 'E-mails',
    defaultMessage: 'E-mail',
  },
  office: {
    id: 'office',
    defaultMessage: 'Ufficio',
  },
  notes: {
    id: 'Notes',
    defaultMessage: 'Note',
  },
  other_info: {
    id: 'other_info',
    defaultMessage: 'Ulteriori informazioni',
  },
  officeAddress: {
    id: 'officeAddress: ',
    defaultMessage: 'Indirizzo ufficio: ',
  },
  otherSocial: {
    id: 'otherSocial',
    defaultMessage: 'Altro social: ',
  },
});

const PNAdministratorContacts = ({ content }) => {
  const intl = useIntl();

  return content?.mobilePhone ||
    content?.phone ||
    content?.email ||
    content?.officeAddress ? (
    <RichTextSection
      tag_id={'title-administrator-contacts'}
      field="text"
      title={intl.formatMessage(messages.contacts)}
      show_title={true}
    >
      <Card
        className="card-bg rounded my-3 administrator-main-card"
        noWrapper={false}
        space
        tag="div"
      >
        <CardBody tag="div">
          <Row>
            <Col className={'administrator-info mt-4'} md="12">
              {/* PHONE */}
              {content?.phone && (
                <p className="card-text">
                  <Icon
                    icon="it-telephone"
                    padding={true}
                    className="icon-administrator"
                    alt="phone"
                  />
                  <ContactLink tel={content.phone} label={false} />
                </p>
              )}
              {/* MOBILE PHONE */}
              {content?.mobilePhone && (
                <p className="card-text">
                  <Icon
                    icon="it-telephone"
                    padding={true}
                    className="icon-administrator"
                    alt="mobile phone"
                  />
                  <ContactLink tel={content.mobilePhone} label={false} />
                </p>
              )}
              {/* EMAIL */}
              {content?.email && (
                <p className="card-text">
                  <Icon
                    icon="it-mail"
                    padding={true}
                    className="icon-administrator"
                  />
                  <ContactLink email={content.email} label={false} />
                </p>
              )}
              {/* OFFICE ADDRESS */}
              {content?.officeAddress && (
                <p className="card-text p-2">
                  <h6 className="m-0 mb-2">
                    {intl.formatMessage(messages.officeAddress)}
                  </h6>
                  <p className="mb-0">{content.officeAddress}</p>
                </p>
              )}
              <p className="card-text">
                {content?.twitter_link && (
                  <a href={flattenToAppURL(content?.twitter_link)}>
                    <Icon
                      icon="it-twitter"
                      padding={true}
                      className="icon-administrator"
                      size={25}
                    />
                  </a>
                )}
                {content?.linkedin_link && (
                  <a href={flattenToAppURL(content?.linkedin_link)}>
                    <Icon
                      icon="it-linkedin"
                      padding={true}
                      className="icon-administrator"
                      size={25}
                    />
                  </a>
                )}
                {content?.facebook_link && (
                  <a href={flattenToAppURL(content?.facebook_link)}>
                    <Icon
                      icon="it-facebook"
                      padding={true}
                      className="icon-administrator"
                      size={25}
                    />
                  </a>
                )}
                {content?.instagram_link && (
                  <a href={flattenToAppURL(content?.instagram_link)}>
                    <Icon
                      icon="it-instagram"
                      padding={true}
                      className="icon-administrator"
                      size={25}
                    />
                  </a>
                )}
              </p>
              {content?.other_socialnetwork_link && (
                <p>
                  <Icon
                    icon="it-link"
                    padding={true}
                    className="icon-administrator"
                    size={25}
                  />
                  <a href={flattenToAppURL(content?.other_socialnetwork_link)}>
                    {content?.other_socialnetwork_name
                      ? content.other_socialnetwork_name
                      : content.other_socialnetwork_link}
                  </a>
                </p>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </RichTextSection>
  ) : (
    ''
  );
};

PNAdministratorContacts.propTypes = {
  content: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    officeAddress: PropTypes.string,
    twitter_link: PropTypes.string,
    linkedin_link: PropTypes.string,
    facebook_link: PropTypes.string,
    instagram_link: PropTypes.string,
    other_socialnetwork_link: PropTypes.string,
    other_socialnetwork_name: PropTypes.string,
  }).isRequired,
};

export default PNAdministratorContacts;
