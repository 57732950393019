import WysiwygWidget from '@plone/volto/components/manage/Widgets/WysiwygWidget';
import OfficeTimeWidget from 'components/widget/OfficeTimeWidget';

const widgetConf = {
  richtext: WysiwygWidget,
  officetimewidget: OfficeTimeWidget,
};
const factoryConf = {};
const typeConf = {};
const idConf = {};

const getPordenoneWidgets = (config) => {
  const widgets = {
    widget: {
      ...config.widgets.widget,
      ...widgetConf,
    },
    factory: {
      ...config.widgets.factory,
      ...factoryConf,
    },
    type: {
      ...config.widgets.type,
      ...typeConf,
    },
    id: {
      ...config.widgets.id,
      ...idConf,
    },
  };
  return widgets;
};

export default getPordenoneWidgets;
