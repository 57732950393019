/**
 * Active instances list component.
 * @module components/Features/OnlineService/ActiveInstancesList
 *
 * Display the list of the active instances for this service.
 *
 */
import { Icon, Table } from 'design-react-kit';
import { Button } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  new_request: {
    id: 'new_request',
    defaultMessage: 'Aggiungi nuova richiesta',
  },
  onlineService_description: {
    id: 'onlineService_description',
    defaultMessage: 'Sono già aperte le seguenti istanze',
  },
});

const ActiveInstancesList = ({ instances, action, children }) => {
  /**
   * On Edit icon click.
   *
   * @param {object} data
   */
  const onEditIconClick = (data) => {
    action(data);
  };
  const intl = useIntl();

  return (
    <>
      <p id="onlineService_description">
        {intl.formatMessage(messages.onlineService_description)}
      </p>
      <Table hover>
        <thead>
          <tr>
            <th scope="col" key="Istanza">
              Istanza
            </th>
            <th scope="col" key="Stato">
              Stato
            </th>
            <th scope="col" key="Azione">
              Azione
            </th>
          </tr>
        </thead>
        <tbody>
          {instances.map((instance, index) => (
            <tr key={index}>
              <th scope="row">{instance.description}</th>
              <td>{instance.state}</td>
              <td>
                <span className="cursor-pointer">
                  <Icon
                    color="primary"
                    icon="it-external-link"
                    onClick={() => onEditIconClick(instance)}
                    title="Apri l'istanza"
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div id="page-button" className="mb-4">
        <Button
          type="button"
          className="basic btn-primary btn mobile-full text-white"
          aria-label="Play or Pause Slideshow"
          onClick={() => action({})}
        >
          {intl.formatMessage(messages.new_request)}
        </Button>
      </div>
    </>
  );
};

export default ActiveInstancesList;
