/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import { loginInfoReducer } from './logininfo';
import { onlineServiceReducer } from './onlineservice';
import { portalUserReducer } from './portaluser';
import {
  avaiableSlotsReducer,
  createBookingReducer,
  getBookingsReducer,
  deleteBookingReducer,
} from './reservationinfo';
import { emailIssueReducer } from './emailIssue';

const reducers = {
  ...defaultReducers,
  // login info
  loginInfo: loginInfoReducer,
  // portal user
  portalUser: portalUserReducer,

  // online service
  onlineService: onlineServiceReducer,

  // reservation Avaiable Slots
  avaiableSlots: avaiableSlotsReducer,

  // create reservation
  createBooking: createBookingReducer,

  // get reservations
  getBookings: getBookingsReducer,

  // delete reservations
  deleteBooking: deleteBookingReducer,

  // send email issue
  emailIssue: emailIssueReducer,
};

export default reducers;
