import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';
import Image from '@plone/volto/components/theme/Image/Image';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Card, CardBody, CardTitle } from 'design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
  attachment: {
    id: 'attachment',
    defaultMessage: 'Allegato',
  },
});

const PNAssociationEventFileImage = ({ content }) => {
  const intl = useIntl();

  return (
    <>
      {content?.image && (
        <a
          data-pat-plone-modal="image: true"
          className="pat-plone-modal"
          href={content?.image?.download}
          title="Vedi immagine"
        >
          <Image
            item={content}
            alt={content?.filename}
            className="img-fluid mb-4 col-6"
            target="_blank"
          />
          <figcaption className="figure-caption text-left pt-3">
            {content?.filename}
          </figcaption>
        </a>
      )}
      {content?.file && (
        <RichTextSection
          tag_id="Allegati"
          title={intl.formatMessage(messages.attachment)}
          className="mt-2"
        >
          <Card
            className="card card-teaser shadow p-4 mt-3 rounded attachment"
            noWrapper={true}
            tag="div"
          >
            <Icon
              icon="it-clip"
              alt={intl.formatMessage(messages.attachment)}
              title={intl.formatMessage(messages.attachment)}
            />
            <CardBody tag="div">
              <CardTitle tag="h5">
                <a
                  href={flattenToAppURL(content?.download)}
                  data-element="association-file"
                >
                  {content?.file?.filename}
                </a>
              </CardTitle>
            </CardBody>
          </Card>
        </RichTextSection>
      )}
    </>
  );
};

PNAssociationEventFileImage.propTypes = {
  content: PropTypes.shape({
    prezzo: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default PNAssociationEventFileImage;
