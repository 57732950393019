import {
  PNAdministrator,
  PNAdministratorsView,
  PNAnnouncementView,
  PNAnnouncementsContainerView,
  PNAssociationCategory,
  PNAssociationEvent,
  PNAssociationView,
  PNAttachmentsView,
  PNEventContainerView,
  PNEventView,
  PNMultipleModuleView,
  PNNewsView,
  PNOfficeView,
  PNPaginaArgomentoView,
  PNPersonView,
  PNProcedureView,
  PNPressRelease,
  PNReservationFolder,
  PNReservationType,
} from '../components/View';

import { Reservation, ReservationConfirmation } from './../components/Features';

const contentTypesViews = {
  PNAdministrator: PNAdministrator,
  PNAdministratorsView: PNAdministratorsView,
  PNAssociation: PNAssociationView,
  PNAssociationEvent: PNAssociationEvent,
  PNAssociationCategory: PNAssociationCategory,
  PNEventsContainer: PNEventContainerView,
  PNEvent: PNEventView,
  PNNewsItem: PNNewsView,
  PNOffice: PNOfficeView,
  PNPerson: PNPersonView,
  PNProcedure: PNProcedureView,
  PNService: PNProcedureView,
  PNOfficeAttachment: PNAttachmentsView,
  PNEventFile: PNAttachmentsView,
  PNNewsFile: PNAttachmentsView,
  PNServiceFile: PNAttachmentsView,
  PNPersonFile: PNAttachmentsView,
  PNAnnouncementsContainer: PNAnnouncementsContainerView,
  PNAnnouncement: PNAnnouncementView,
  PNPaginaArgomento: PNPaginaArgomentoView,
  PNModule: PNAttachmentsView,
  PNMultipleModule: PNMultipleModuleView,
  PNPressRelease: PNPressRelease,
  PrenotazioniFolder: Reservation,
  Prenotazione: ReservationConfirmation,
  PrenotazioneType: PNReservationType,
};
const layoutViews = {};
const layoutViewsNamesMapping = {};

const getPordenoneViews = (config) => {
  return {
    contentTypesViews: {
      ...config.views.contentTypesViews,
      ...contentTypesViews,
    },
    layoutViews: {
      ...config.views.layoutViews,
      ...layoutViews,
    },
    layoutViewsNamesMapping: {
      ...config.views.layoutViewsNamesMapping,
      ...layoutViewsNamesMapping,
    },
  };
};

export default getPordenoneViews;
