/**
 * Allowed extensions proxy.
 * @module proxies/dms
 *
 * Proxy for allowed extensions.
 *
 */
import { makeGetRequest } from './utils';

export const getDefaultCategory = async (): Promise<void> => {
  const { data } = await makeGetRequest(`configuration/system`);
  return data.cm_system_dms_category || '';
};

export const fetchAllowedExtensions = async (stringToHex: (str: string) => string): Promise<void> => {
  const dmsCategory = await getDefaultCategory();
  if (dmsCategory) {
    try {
      return makeGetRequest(`dms/categories/${stringToHex(dmsCategory)}/values`);
    } catch (error) {
      console.error('Error deleting reservation:', error);
    }
  }
};
