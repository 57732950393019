/**
 * Issue view component.
 * @module components/View/Issue
 */
import {
  PageHeader,
  ContentTypeViewSections,
  SkipToMainContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';
import { Col } from 'design-react-kit';
import PropTypes from 'prop-types';
import { createRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IssueStepper from './IssueStepper';
import IssuePrivacy from './IssuePrivacy';
import IssueData from './IssueData';
import IssueSummary from './IssueSummary';
import {
  Container,
  Accordion,
  AccordionHeader,
  Progress,
  AccordionBody,
} from 'design-react-kit';
import IssueStepperNav from './IssueStepperNav';
import { Helmet } from '@plone/volto/helpers';
import cx from 'classnames';

const messages = defineMessages({
  issueTitle: {
    id: 'issueTitle',
    defaultMessage: 'Segnalazione disservizio',
  },
  index: {
    id: 'index',
    defaultMessage: 'Indice della pagina',
  },
});

export const IssueSectionsOrder = [
  { /* DATA */ component: IssueData },
  { /* SUMMARY */ component: IssueSummary },
  { /* STEPPERNAV */ component: IssueStepperNav },
];

/**
 * Issue view component class.
 * @function Issue
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const Issue = (props) => {
  const intl = useIntl();
  let documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);

  useEffect(() => {
    if (documentBody.current && __CLIENT__) {
      setSideMenuElements(documentBody.current);
    }
  }, [documentBody]);
  const content = useSelector((state) => state.content.data);
  const [readingtime, setReadingtime] = useState(0);
  const [currentState, setCurrentState] = useState(1);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [images, setImages] = useState([]);
  const [address, setAddress] = useState('');
  const [issueType, setIssueType] = useState('');
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [fiscalNumber, setFiscalNumber] = useState('');
  const [validationError, setValidationError] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [progressValue, setProgressValue] = useState(0);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / scrollHeight) * 100;
      setProgressValue(scrollPercentage);
    };

    document.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    sideMenuElements &&
      sideMenuElements.children &&
      Array.from(sideMenuElements.children).map((section) => {
        setActiveSection(section.id);
      });
  }, [activeSection]);

  if (!currentState) {
    setCurrentState(1);
  }

  return (
    <>
      <Container className="container px-4 my-4 newsitem-view">
        <Helmet title={intl.formatMessage(messages.issueTitle)} />
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />
        <IssueStepper
          content={content}
          currentState={currentState}
          setCurrentState={setCurrentState}
        />
        {!privacyAccepted ? (
          <IssuePrivacy
            checked={privacyAccepted}
            setChecked={setPrivacyAccepted}
            currentState={currentState}
            setCurrentState={setCurrentState}
          />
        ) : (
          <div className="row row-column-border border-light row-column-menu-left">
            <Col tag="aside" lg={4}>
              <div className="sticky-wrapper navbar-wrapper page-side-menu">
                <nav className="navbar it-navscroll-wrapper navbar-expand-lg">
                  <div className="menu-wrapper">
                    <div className="link-list-wrapper menu-link-list">
                      <div className="accordion-wrapper">
                        <Accordion>
                          <AccordionHeader
                            active={isNavOpen}
                            onToggle={() => {
                              setIsNavOpen(!isNavOpen);
                            }}
                            aria-controls="side-menu-body"
                          >
                            <h3>{intl.formatMessage(messages.index)}</h3>
                          </AccordionHeader>
                          <div className="mb-3">
                            <Progress
                              value={progressValue}
                              role="progressbar"
                            />
                          </div>
                          <AccordionBody
                            active={isNavOpen}
                            id="side-menu-body"
                            role="region"
                          >
                            <ul className="link-list" data-element="page-index">
                              {sideMenuElements &&
                                sideMenuElements.children &&
                                Array.from(sideMenuElements.children).map(
                                  (item) => {
                                    if (item.nodeName === 'SECTION') {
                                      return (
                                        <li className="nav-item" key={item.id}>
                                          <a
                                            className={cx('nav-link active', {
                                              active: item.id === activeSection,
                                            })}
                                            href={`#${item.id}`}
                                            onClick={() => {
                                              document
                                                .getElementById(item?.id)
                                                ?.scrollIntoView?.({
                                                  behavior: 'smooth',
                                                  block: 'start',
                                                });
                                            }}
                                            id={`item-${item.id}`}
                                          >
                                            <span>{item.id}</span>
                                          </a>
                                        </li>
                                      );
                                    }
                                  },
                                )}
                            </ul>
                          </AccordionBody>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </Col>
            <section
              className="col-lg-8 it-page-sections-container border-light"
              id="main-content-section"
              ref={documentBody}
            >
              {/* SEZIONI */}
              <ContentTypeViewSections
                content={content}
                defaultSections={IssueSectionsOrder.map((section, index) => ({
                  ...section,
                  props: {
                    currentState,
                    setCurrentState,
                    images,
                    setImages,
                    address,
                    setAddress,
                    issueType,
                    setIssueType,
                    title,
                    setTitle,
                    details,
                    setDetails,
                    firstName,
                    setFirstName,
                    lastName,
                    setLastName,
                    phone,
                    setPhone,
                    email,
                    setEmail,
                    fiscalNumber,
                    setFiscalNumber,
                    validationError,
                    setValidationError,
                  },
                }))}
              />
            </section>
          </div>
        )}
      </Container>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Issue.propTypes = {
  content: PropTypes.shape({}).isRequired,
};

export default Issue;
