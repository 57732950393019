/**
 * PNNewsView view component.
 * @module components/View/PNNewsItemView
 */

import {
  NewsItemPlaceholderAfterContent,
  PageHeader,
  RelatedItems,
  useSideMenu,
  ContentTypeViewSections,
  SkipToMainContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  PNAlertBehavior,
  PNAttachments,
  PNImageGallery,
  PNCuredBy,
} from 'comune-pordenone-plone-theme/components/View';
import { createRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Image from '@plone/volto/components/theme/Image/Image';
import PNNewsItemText from './PNNewsItemText';
import PNNewsMoreInformation from './PNNewsMoreInformation';
import PNNewsLocation from './PNNewsLocation';
import PropTypes from 'prop-types';
import { Row, Col } from 'design-react-kit';
import { viewDate } from 'design-comuni-plone-theme/helpers';

export const NewsSectionsOrder = [
  { /* TEXT */ component: PNNewsItemText },
  { /* IMAGES */ component: PNImageGallery },
  { /* ATTACHMENTS */ component: PNAttachments },
  { /* CURED BY */ component: PNCuredBy },
  { /* LOCATIONS */ component: PNNewsLocation },
  { /* MORE INFORMATION */ component: PNNewsMoreInformation },
];

const messages = defineMessages({
  date: {
    id: 'date',
    defaultMessage: 'Data:',
  },
});

/**
 * PNNewsView view component class.
 * @function PNNewsView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNNewsView = ({ content, location }) => {
  const intl = useIntl();
  const [readingtime, setReadingtime] = useState(0);
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);

  let related_items = [];
  if (content?.notizie_correlate?.length > 0) {
    related_items = [...related_items, ...content.notizie_correlate];
  }
  if (content?.relatedItems?.length > 0) {
    related_items = [...related_items, ...content.relatedItems];
  }
  const modifiedContent = {
    ...content,
    tassonomia_argomenti:
      content?.tipologia_notizia?.length > 0
        ? content.tipologia_notizia
        : undefined,
  };

  return (
    <>
      <div className="container px-4 my-4 newsitem-view">
        <SkipToMainContent />
        <PageHeader
          content={modifiedContent}
          readingtime={readingtime}
          showreadingtime={true}
          showdates={false}
          showtassonomiaargomenti={true}
        />
        {content?.effective && (
          <Row class="row mb-4 readingtime-dates">
            <Col class="col-6">
              <Row class="row">
                <Col class="col-12">
                  <small>Data:</small>
                  <p class="font-monospace">
                    {/* {formatDate({
                    date: content?.effective,
                    // format: 'dd-MM-yyyy',
                    format: 'DD mmmm yy',
                    locale: 'it-IT',
                    // long: true,
                    // includeTime: false,
                  })} */}
                    {viewDate(intl.locale, content?.effective, 'DD MMMM YYYY')}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
        />

        {/* HEADER IMAGE */}
        <div className="row row-column-border border-light row-column-menu-left">
          <aside className="col-lg-4">
            <SideMenu data={sideMenuElements} content_uid={content?.UID} />
          </aside>
          <section
            className="col-lg-8 it-page-sections-container border-light"
            id="main-content-section"
            ref={documentBody}
          >
            {/* SEZIONI */}
            {content?.image && (
              <div>
                <Image
                  item={content}
                  alt={content.filename}
                  className=""
                  responsive={true}
                />
                <figcaption className="figure-caption text-center pt-3">
                  {content.image_caption}
                </figcaption>
              </div>
            )}
            <ContentTypeViewSections
              content={content}
              defaultSections={NewsSectionsOrder}
            />
          </section>
        </div>
      </div>
      <NewsItemPlaceholderAfterContent content={content} />
      <RelatedItems content={content} />
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNNewsView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    effective: PropTypes.string,
    expires: PropTypes.string,
    image: PropTypes.object,
    image_caption: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
    dataset: PropTypes.shape({
      data: PropTypes.string,
    }),
    modified: PropTypes.string,
    rights: PropTypes.string,
    luoghi_correlati: PropTypes.array,
    related_news: PropTypes.array,
    tassonomia_argomenti: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        token: PropTypes.string,
      }),
    ),
    tipologia_notizia: PropTypes.shape({
      title: PropTypes.string,
      token: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default PNNewsView;
