/**
 * PNReservationType view component.
 * @module components/View/PNReservationType
 */
import { defineMessages, useIntl } from 'react-intl';
import {
  PageHeader,
  RelatedItems,
  useSideMenu,
  ContentTypeViewSections,
  SkipToMainContent,
  Metadata,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { createRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Container, Row, Col } from 'design-react-kit';
import PNReservationTypeContent from './PNReservationTypeContent';

export const ReservationTypeSectionsOrder = [
  { /* CONTENT */ component: PNReservationTypeContent },
];

const messages = defineMessages({
  duration: {
    id: 'duration',
    defaultMessage: 'Durata',
  },
  requirements: {
    id: 'requirements',
    defaultMessage: 'Cosa serve',
  },
});
/**
 * PNReservationType view component class.
 * @function PNReservationType
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNReservationType = ({ content, location }) => {
  const intl = useIntl();
  let documentBody = createRef();
  const [readingtime, setReadingtime] = useState(0);
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);
  return (
    <>
      <Container className="container px-4 my-4 newsitem-view">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />
        {/* HEADER IMAGE */}
        <Row className="border-top row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            {documentBody.current && __CLIENT__ && (
              <SideMenu data={sideMenuElements} content_uid={content?.UID} />
            )}
          </Col>
          <section
            ref={documentBody}
            id="reservation-main-content-section"
            className="col-lg-8 it-page-sections-container"
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={ReservationTypeSectionsOrder}
            />
            <Metadata content={content} title={' '} />
          </section>
        </Row>
      </Container>
      <RelatedItems list={content?.relatedItems} />
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNReservationType.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default PNReservationType;
