/**
 * PNReservationTypeContent view component.
 * @module components/View/PNReservationTypeContent
 */
import { defineMessages, useIntl } from 'react-intl';
import {
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import PropTypes from 'prop-types';

const messages = defineMessages({
  duration: {
    id: 'duration',
    defaultMessage: 'Durata',
  },
  requirements: {
    id: 'requirements',
    defaultMessage: 'Cosa serve',
  },
});
/**
 * PNReservationTypeContent view component class.
 * @function PNReservationTypeContent
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNReservationTypeContent = ({ content, location }) => {
  const intl = useIntl();

  return content?.duration || richTextHasContent(content?.requirements) ? (
    <>
      {/* Duration */}
      {/* DA MODIFICARE !!!!!!!!!!!!!!!!!!!!!!!!! */}
      {content?.duration && (
        <RichTextSection
          tag_id={'reservation-type-duration'}
          field="text"
          title={intl.formatMessage(messages.duration)}
          show_title={true}
        >
          <p>{content.duration.title}</p>
        </RichTextSection>
      )}

      {/* Requirements */}
      {richTextHasContent(content?.requirements) && (
        <RichTextSection
          tag_id={'eservation-type-requirements'}
          field="text"
          title={intl.formatMessage(messages.requirements)}
          show_title={true}
          data={content?.requirements}
        />
      )}
    </>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNReservationTypeContent.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default PNReservationTypeContent;
