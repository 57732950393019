/**
 * PNPersonContacts view component.
 * @module components/theme/View/PNPersonContacts
 */

import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardText } from 'design-react-kit';
import {
  RichTextSection,
  ContactLink,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const messages = defineMessages({
  contacts: {
    id: 'contacts',
    defaultMessage: 'Contatti',
  },
  phones: {
    id: 'phones',
    defaultMessage: 'Telefono',
  },
  emails: {
    id: 'E-mails',
    defaultMessage: 'E-mail',
  },
});

// ... (other imports)

const PNPersonContacts = ({ content }) => {
  const intl = useIntl();

  return content?.phones?.length > 0 || content?.emails?.length > 0 ? (
    <RichTextSection
      tag_id="contatti"
      title={intl.formatMessage(messages.contacts)}
      lighthouseId={'contacts'}
      show_title={true}
    >
      <div className="col-12 col-md-8 mb-30">
        <div className="mb-5">
          <Card
            className="ccard card-teaser rounded shadow mt-3"
            data-element="service-area"
          >
            <CardBody className="card-body pe-3">
              {/* Phones */}
              {content?.phones?.length > 0 && (
                <div className="card-text">
                  {content?.phones.map((phone, index) => (
                    <p className="card-text u-main-black">
                      <Icon
                        icon="it-telephone"
                        padding={true}
                        className="icons-ServiceContacts"
                      />
                      <ContactLink tel={phone} label={false} />
                    </p>
                  ))}
                </div>
              )}
              {/* Emails */}
              {content?.emails?.length > 0 && (
                <div className="card-text">
                  {content?.emails.map((email, index) => (
                    <p className="card-text u-main-black">
                      <Icon
                        icon="it-mail"
                        padding={true}
                        className="icons-ServiceContacts"
                      />
                      <ContactLink email={email} label={false} />
                    </p>
                  ))}
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </RichTextSection>
  ) : (
    ''
  );
};

PNPersonContacts.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNPersonContacts;
