/**
 * IssueData view component.
 * @module components/View/IssueData
 */
import { defineMessages, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Input,
  TextArea,
  Button,
  Icon,
  Card,
  CardHeader,
  CardBody,
} from 'design-react-kit';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { useSelector } from 'react-redux';
import cx from 'classnames';

const messages = defineMessages({
  issue: {
    id: 'issue',
    defaultMessage: 'Disservizio',
  },
  place: {
    id: 'place',
    defaultMessage: 'Luogo',
  },
  input_place: {
    id: 'input_place',
    defaultMessage: 'Inserisci il luogo *',
  },
  place_text: {
    id: 'place_text',
    defaultMessage: 'Indica il luogo del disservizio',
  },
  use_position: {
    id: 'use_position',
    defaultMessage: 'Usa la tua posizione',
  },
  title_required: {
    id: 'title_required',
    defaultMessage: 'Titolo *',
  },
  details: {
    id: 'details',
    defaultMessage: 'Details',
  },
  max_length: {
    id: 'max_length',
    defaultMessage: 'Inserire al massimo 200 caratteri',
  },
  type_issue: {
    id: 'type_issue',
    defaultMessage: 'Tipo di disservizio',
  },
  issue_author: {
    id: 'issue_author',
    defaultMessage: 'Autore della segnalazione',
  },
  user_info: {
    id: 'user_info',
    defaultMessage: 'Informazioni su di te',
  },
  images: {
    id: 'images',
    defaultMessage: 'Immagini',
  },
  images_description: {
    id: 'images_description',
    defaultMessage:
      'È possibile allegare alla segnalazione fino ad un massimo di 3 immagini',
  },
  upload_file: {
    id: 'upload_file',
    defaultMessage: 'Carica file',
  },
  requester: {
    id: 'requester',
    defaultMessage: 'Richiedente',
  },
  requesterDescription: {
    id: 'requesterDescription',
    defaultMessage: 'Informazioni su di te',
  },
  firstName_required: {
    id: 'firstName_required',
    defaultMessage: 'Nome *',
  },
  firstNameDescription: {
    id: 'firstNameDescription',
    defaultMessage: 'Inserisci il tuo nome',
  },
  lastName_required: {
    id: 'lastName_required',
    defaultMessage: 'Cognome *',
  },
  lastNameDescription: {
    id: 'lastNameDescription',
    defaultMessage: 'Inserisci il tuo cognome',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Telefono',
  },
  email_required: {
    id: 'email_required',
    defaultMessage: 'E-mail *',
  },
  emailDescription: {
    id: 'emailDescription',
    defaultMessage: 'Inserisci la tua email',
  },
  showEverything: {
    id: 'showEverything',
    defaultMessage: 'Mostra tutto',
  },
  contacts: {
    id: 'contacts',
    defaultMessage: 'Contatti',
  },
  fiscalNumber: {
    id: 'fiscalNumber',
    defaultMessage: 'Codice fiscale',
  },
  option_test: {
    id: 'option_test',
    defaultMessage: 'Danneggiamento proprietà pubblica',
  },
  max_images: {
    id: 'max_images',
    defaultMessage: 'È possibile caricare un massimo di 3 immagini',
  },
  required_field: {
    id: 'required_field',
    defaultMessage: 'Questo campo è obbligatorio',
  },
  invalid_email: {
    id: 'invalid_email',
    defaultMessage: `L'indirizzo email inserito non è valido`,
  },
  data_issue: {
    id: 'data_issue',
    defaultMessage: 'Dati di segnalazione',
  },
});

/**
 * IssueData view component class.
 * @function IssueData
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const IssueData = ({
  currentState,
  images,
  setImages,
  address,
  setAddress,
  issueType,
  setIssueType,
  title,
  setTitle,
  setDetails,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phone,
  setPhone,
  email,
  setEmail,
  fiscalNumber,
  setFiscalNumber,
  validationError,
}) => {
  const intl = useIntl();
  const userSession = useSelector((state) => state.portalUser);
  const [addressErr, setAddressErr] = useState();
  const [typeErr, setTypeErr] = useState();
  const [tltErr, setTltErr] = useState();
  const [firstErr, setFirstErr] = useState();
  const [lastErr, setLastErr] = useState();
  const [emailErr, setEmailErr] = useState();

  if (userSession.isAuthenticated === true) {
    setFirstName(userSession?.user?.first_name);
    setLastName(userSession?.user?.last_name);
  }

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const getAddressFromCoordinates = async (latitude, longitude) => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
    );
    const data = await response.json();
    setAddress(data.display_name);
  };

  const getPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getAddressFromCoordinates(latitude, longitude);
        },
        (error) => {
          console.error('Error getting position:', error);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleChange = (event) => {
    setIssueType(event.target.value);
    event.preventDefault();
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    if (validationError !== null) {
      if (validationError) {
        setAddressErr(address ? true : false);
        setTypeErr(issueType ? true : false);
        setTltErr(title ? true : false);
        setFirstErr(firstName ? true : false);
        setLastErr(lastName ? true : false);
        setEmailErr(email && validateEmail(email) ? true : false);
      } else {
        setAddressErr(true);
        setTypeErr(true);
        setTltErr(true);
        setFirstErr(true);
        setLastErr(true);
        setEmailErr(true);
      }
    }
  }, [validationError, address, issueType, title, firstName, lastName, email]);

  return currentState === 2 ? (
    <RichTextSection
      tag_id={intl.formatMessage(messages.data_issue)}
      id={intl.formatMessage(messages.data_issue)}
      title_tag="h4"
    >
      <RichTextSection
        className="it-page-section"
        id="report-place"
        tag_id="report-place"
        title_tag="h4"
        title={intl.formatMessage(messages.place)}
        show_title={true}
      >
        <Card className="card p-3 my-4 h-100 border rounded shadow-sm ">
          <CardBody className="mt-4">
            <Input
              type="text"
              id="place-text"
              required={true}
              label={intl.formatMessage(messages.input_place)}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="mb-0"
              valid={addressErr}
              validationText={
                !typeErr && validationError
                  ? intl.formatMessage(messages.required_field)
                  : ''
              }
            />
            <p>{intl.formatMessage(messages.place_text)}</p>
            <a
              href="#"
              disabled
              onClick={(e) => {
                e.preventDefault();
                getPosition();
              }}
              className="link"
            >
              <Icon icon="it-map-marker-circle" />
              {intl.formatMessage(messages.use_position)}
            </a>
          </CardBody>
        </Card>
      </RichTextSection>
      <RichTextSection
        className="it-page-section"
        id="issue-info"
        tag_id="issue-info"
        title_tag="h4"
        title={intl.formatMessage(messages.issue)}
        show_title={true}
      >
        <Card className="card p-3 my-4 h-100 border rounded shadow-sm">
          <CardBody className="mt-4" id="issue-info-body">
            <FormGroup className="mb-5">
              <select
                type="text"
                id="select-issue-type"
                required={true}
                value={issueType}
                className={cx('p-0 col-12', {
                  border: !typeErr && validationError,
                  'border-danger': !typeErr && validationError,
                })}
                onChange={handleChange}
                valid={typeErr}
              >
                <option
                  key={'option-type-0'}
                  id="option-type-0"
                  value={intl.formatMessage(messages.type_issue)}
                >
                  {intl.formatMessage(messages.type_issue)}
                </option>
                <option
                  key={'option-type-1'}
                  id="option-type-1"
                  value={intl.formatMessage(messages.option_test)}
                >
                  {intl.formatMessage(messages.option_test)}
                </option>
              </select>
              {!typeErr && validationError ? (
                <label
                  htmlFor="select-issue-type"
                  className=" form-feedback just-validate-error-label form-text form-feedback just-validate-error-label"
                >
                  {intl.formatMessage(messages.required_field)}
                </label>
              ) : (
                ''
              )}
            </FormGroup>
            <Input
              type="text"
              id="title"
              required={true}
              label={intl.formatMessage(messages.title_required)}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              valid={tltErr}
              validationText={
                !tltErr && validationError
                  ? intl.formatMessage(messages.required_field)
                  : ''
              }
            />
            <TextArea
              type="text"
              required={true}
              id="details"
              label={intl.formatMessage(messages.details)}
              infoText={intl.formatMessage(messages.max_length)}
              onChange={(e) => setDetails(e.target.value)}
            />
            <div className="btn-wrapper pt-2 pb-3 pb-lg-4 pt-lg-0 bg-white">
              <label className="title-xsmall-bold u-grey-dark pb-2 ms-2">
                {intl.formatMessage(messages.images)}
              </label>
              <div className="upload-wrapper d-flex flex-column">
                {images?.map((image, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center mb-2"
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt=""
                      className="img"
                      style={{ width: '50px', height: '50px' }}
                    />
                    <span className="t-primary fw-bold w-100 ms-2">
                      {image.name}
                    </span>
                    <Button
                      onClick={() => handleRemoveImage(index)}
                      className="align-self-center"
                      aria-label="elimina immagina caricata"
                    >
                      <Icon icon="it-close" />
                    </Button>
                  </div>
                ))}
              </div>
              <hr />
              <Input
                type="file"
                multiple
                onChange={handleImageUpload}
                style={{
                  display: 'none',
                }}
                id="file-upload"
                className={'w-100 f-bold btn btn-primary mobile-full'}
                disabled={images?.length >= 3}
              />
              <label
                htmlFor="file-upload"
                className="m-0 pe-auto btn w-100 fw-bold border border-primary"
                style={{
                  cursor: 'pointer',
                }}
                aria-label="Carica file per il disservizio"
              >
                {intl.formatMessage(messages.upload_file)}
              </label>
              <p className="title-xsmall u-grey-dark pt-10 mb-0 mt-2">
                {intl.formatMessage(messages.images_description)}
              </p>
            </div>
          </CardBody>
        </Card>
      </RichTextSection>
      <RichTextSection
        className="it-page-section cmp-card bg-gray"
        id="report-issue-author"
        tag_id="report-issue-author"
        title_tag="h4"
        title={intl.formatMessage(messages.issue_author)}
        show_title={true}
      >
        <p>{intl.formatMessage(messages.user_info)}</p>
        <Card className="card p-3 my-4 h-100 shadow-sm border rounded">
          <CardHeader className="border-0 p-0 mb-lg-20">
            <h4 className="mt-4 px-4">
              {userSession.isAuthenticated === false
                ? intl.formatMessage(messages.requester)
                : userSession?.user?.first_name +
                  ' ' +
                  userSession?.user?.last_name}
            </h4>
          </CardHeader>
          <CardBody className="mt-4">
            {userSession.isAuthenticated === true ? (
              <Input
                type="text"
                label={intl.formatMessage(messages.fiscalNumber)}
                value={userSession?.user?.fiscal_number || fiscalNumber}
                onChange={(e) => setFiscalNumber(e.target.value)}
              />
            ) : (
              <>
                <Input
                  type="text"
                  name={intl.formatMessage(messages.firstName_required)}
                  label={intl.formatMessage(messages.firstName_required)}
                  infoText={intl.formatMessage(messages.firstNameDescription)}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  valid={firstErr}
                  validationText={
                    !firstErr && validationError
                      ? intl.formatMessage(messages.required_field)
                      : ''
                  }
                />
                <Input
                  type="text"
                  name={intl.formatMessage(messages.lastName_required)}
                  label={intl.formatMessage(messages.lastName_required)}
                  infoText={intl.formatMessage(messages.lastNameDescription)}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  valid={lastErr}
                  validationText={
                    !lastErr && validationError
                      ? intl.formatMessage(messages.required_field)
                      : ''
                  }
                />
              </>
            )}
            <Input
              type="email"
              name={intl.formatMessage(messages.email_required)}
              label={intl.formatMessage(messages.email_required)}
              infoText={intl.formatMessage(messages.emailDescription)}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              valid={emailErr}
              validationText={
                !emailErr && validationError
                  ? email
                    ? intl.formatMessage(messages.invalid_email)
                    : intl.formatMessage(messages.required_field)
                  : ''
              }
            />
            <Input
              type="text"
              label={intl.formatMessage(messages.phone)}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </CardBody>
        </Card>
      </RichTextSection>
    </RichTextSection>
  ) : (
    <></>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IssueData.propTypes = {
  content: PropTypes.shape({}),
};

export default IssueData;
