/**
 * LookupArray attribute.
 * @module fields/lookupArray
 *
 */
import { FormGroup, FormText, Input, Label } from 'design-react-kit';
import { evaluateEcqlFilter, setAutoValue, updateVisibility, validateValue } from '../utilities/fieldshelper';
import { useEffect, useState } from 'react';

import { getFieldLabel } from './utils';
import { getLookupValuesProxy } from '../proxies/lookup';
import { useIntl } from 'react-intl';

export const LookupArrayField = ({ config, context, recordUpdater, showIfFn, showIfBindings, showIfApis, filterBindings, validationRuleFn, validationRuleBindings, validationRuleApis, autoValueFn, autoValueBindings, autoValueApis }) => {
  const intl = useIntl();
  const required = config.mandatory ? true : undefined;

  const [value, setValue] = useState(context?.[config.name]);
  const [svalue, setSValue] = useState(JSON.stringify(context?.[config.name]));
  const [errors, setErrors] = useState('');
  const [options, setOptions] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    getLookupValuesProxy(config.lookupType, {
      filter: {
        ecql: evaluateEcqlFilter(config.ecqlFilter, context),
      },
    }).then(({ data, meta }) => {
      setOptions(data);
    });
  }, filterBindings);

  /**
   * On value change
   */
  useEffect(() => {
    // update value
    let arrayDetails = [];
    value?.forEach(function(el) {
      const selectedRecord = options.find((r: { _id: number }) => r._id == el);
      let detail = {
        "_description_translation": selectedRecord?._description_translation,
        "_id": el,
        "code": selectedRecord?.code,
        "description": selectedRecord?.description
      };
      arrayDetails.push(detail);
    });

    context = {
      ...context,
      [config.name]: value,
      [`_${config.name}_details`]: arrayDetails,
      [`_${config.name}_str`]: svalue,
    };
    recordUpdater(context);

    // validate value
    validateValue(value, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, [svalue]);

  /**
   * On context change
   */
  useEffect(() => {
    if (value != context?.[config.name]) {
      setValue(context?.[config.name]);
      setSValue(JSON.stringify(context?.[config.name]));
    }
  }, [context?.[config.name]]);

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  /**
   * Validation rules
   */
  useEffect(() => {
    validateValue(value, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, validationRuleBindings);

  /**
   * AutoValue rules
   */
  useEffect(() => {
    setAutoValue(autoValueFn, autoValueApis);
  }, autoValueBindings);

  const handleClick = (e: Event) => {
    let attribute_value = value || [];
    let checkbox_value = parseInt(e?.target?.value);
    if (e?.target?.checked) {
      attribute_value.push(checkbox_value);
    } else {
      const index = attribute_value.indexOf(checkbox_value);
      if (index > -1) {
        attribute_value.splice(index, 1);
      }
    }
    setValue(attribute_value);
    setSValue(JSON.stringify(attribute_value)); // used for binding
  };

  return (
    <div hidden={!isVisible} className="form-group">
      <Label className="active">{getFieldLabel(config)}</Label>
      {options.map((opt: any) => (
        <FormGroup check key={opt._id}>
          <Input
            type="checkbox"
            checked={svalue && !!value?.find((item: string) => item == opt._id)}
            value={opt._id}
            id={`field-lookupArray-value-${opt._id}`}
            name={config.name}
            disabled={isDisabled}
            valid={errors ? false : value ? true : undefined}
            required={required}
            onClick={handleClick}
            infoText={config?.help}
          />
          <Label check htmlFor={`field-lookupArray-value-${opt._id}`} style={{ overflow: 'visible', whiteSpace: 'normal' }}>
            {opt._description_translation}
          </Label>
        </FormGroup>
      ))}
      <FormText className="form-text" hidden={!config?.help}>
        {config?.help}
      </FormText>
      <FormText tag="div" color="red" className="invalid-feedback form-feedback just-validate-error-label mt-0 mb-3">
        {errors}
      </FormText>
    </div>
  );
};
