/**
 * reservation reducer
 * @module src/reducers/reservationinfo
 */

import {
  GET_AVAIABLE_SLOTS,
  CREATE_RESERVATION,
  GET_RESERVATIONS,
  DELETE_RESERVATION,
} from 'comune-pordenone-plone-theme/actions';

const initialState = {
  error: null,
  hasError: false,
  result: {},
  loading: false,
};

export const avaiableSlotsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_AVAIABLE_SLOTS}_PENDING`:
      return {
        ...state,
        loading: true,
      };

    case `${GET_AVAIABLE_SLOTS}_SUCCESS`:
      return {
        ...state,
        result: action.result,
        loading: false,
      };

    case `${GET_AVAIABLE_SLOTS}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
        loading: false,
      };

    default:
      return state;
  }
};

export const createBookingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${CREATE_RESERVATION}_PENDING`:
      return {
        ...state,
        loading: true,
      };

    case `${CREATE_RESERVATION}_SUCCESS`:
      return {
        ...state,
        result: action.result,
        loading: false,
      };

    case `${CREATE_RESERVATION}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
        loading: false,
      };

    default:
      return state;
  }
};

export const getBookingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_RESERVATIONS}_PENDING`:
      return {
        ...state,
        loading: true,
      };

    case `${GET_RESERVATIONS}_SUCCESS`:
      return {
        ...state,
        result: action.result,
        loading: false,
      };

    case `${GET_RESERVATIONS}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
        loading: false,
      };

    default:
      return state;
  }
};

export const deleteBookingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${DELETE_RESERVATION}_PENDING`:
      return {
        ...state,
        loading: true,
      };

    case `${DELETE_RESERVATION}_SUCCESS`:
      return {
        ...state,
        result: action.result,
        loading: false,
      };

    case `${DELETE_RESERVATION}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
        loading: false,
      };

    default:
      return state;
  }
};
