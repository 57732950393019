/**
 * View Accordion block.
 * @module components/ItaliaTheme/Blocks/Accordion/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle } from 'design-react-kit';
import {
  ContactLink,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  contacts: {
    id: 'contacts',
    defaultMessage: 'Contatti',
  },
  modules: {
    id: 'modules',
    defaultMessage: 'Moduli',
  },
  pec: {
    id: 'pec: ',
    defaultMessage: 'PEC: ',
  },
  fax: {
    id: 'fax: ',
    defaultMessage: 'Fax: ',
  },
  officeResponsible: {
    id: 'officeResponsible: ',
    defaultMessage: 'Responsabile: ',
  },
  people: {
    id: 'people: ',
    defaultMessage: 'Personale: ',
  },
  time: {
    id: 'time: ',
    defaultMessage: 'Orari',
  },
  officeStaff: {
    id: 'officeStaff: ',
    defaultMessage: 'Personale: ',
  },
});

/**
 * View Accordion block class.
 * @class View
 * @extends Component
 */
const PNOfficeContacts = ({ content, block }) => {
  const intl = useIntl();

  return content?.officeResponsible?.title ||
    content?.responsibleText ||
    content?.officeStaff?.length > 0 ||
    content?.email ||
    content?.pec ||
    content?.phone ||
    content?.fax ||
    content?.people?.title ? (
    <RichTextSection
      tag_id="office-contacts"
      title={intl.formatMessage(messages.contacts)}
      show_title={true}
    >
      <Card className="card-bg rounded my-3" noWrapper={false} space tag="div">
        <CardBody tag="div">
          {/* EMAIL */}
          {content?.email && (
            <p className="card-text">
              <ContactLink email={content.email} label={true} />
            </p>
          )}
          {/* PEC */}
          {content?.pec && (
            <p className="card-text">
              {intl.formatMessage(messages.pec)}
              <ContactLink email={content.pec} label={false} />
            </p>
          )}
          {/* PHONE */}
          {content?.phone && (
            <p className="card-text">
              <ContactLink tel={content.phone} label={true} />
            </p>
          )}
          {/* FAX */}
          {content?.fax && (
            <p className="card-text">
              <ContactLink fax={content.fax} label={true} />
            </p>
          )}
        </CardBody>
      </Card>
    </RichTextSection>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNOfficeContacts.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PNOfficeContacts;
