/**
 * View Accordion block.
 * @module components/ItaliaTheme/Blocks/Accordion/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'design-react-kit';
import {
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  day: {
    id: 'day',
    defaultMessage: 'Giorno',
  },
  time: {
    id: 'time',
    defaultMessage: 'Orari',
  },
  monday: {
    id: 'monday',
    defaultMessage: 'Lunedì',
  },
  tuesday: {
    id: 'tuesday',
    defaultMessage: 'Martedì',
  },
  wednesday: {
    id: 'wednesday',
    defaultMessage: 'Mercoledì',
  },
  thursday: {
    id: 'thursday',
    defaultMessage: 'Giovedì',
  },
  friday: {
    id: 'friday',
    defaultMessage: 'Venerdì',
  },
  saturday: {
    id: 'saturday',
    defaultMessage: 'Sabato',
  },
  morning: {
    id: 'morning',
    defaultMessage: 'Mattina',
  },
  afternoon: {
    id: 'afternoon',
    defaultMessage: 'Pomeriggio',
  },
  from_time: {
    id: 'from_time',
    defaultMessage: 'Dalle',
  },
  to_time: {
    id: 'to_time',
    defaultMessage: 'Alle',
  },
});

const OfficeTimeTable = ({ time }) => {
  const intl = useIntl();

  const timeSlots = [
    {
      day: 'monday',
      morning: time?.length > 0 ? [time[0], time[1]] : ['--:--', '--:--'],
      afternoon: time?.length > 0 ? [time[2], time[3]] : ['--:--', '--:--'],
    },
    {
      day: 'tuesday',
      morning: time?.length > 0 ? [time[4], time[5]] : ['--:--', '--:--'],
      afternoon: time?.length > 0 ? [time[6], time[7]] : ['--:--', '--:--'],
    },
    {
      day: 'wednesday',
      morning: time?.length > 0 ? [time[8], time[9]] : ['--:--', '--:--'],
      afternoon: time?.length > 0 ? [time[10], time[11]] : ['--:--', '--:--'],
    },
    {
      day: 'thursday',
      morning: time?.length > 0 ? [time[12], time[13]] : ['--:--', '--:--'],
      afternoon: time?.length > 0 ? [time[14], time[15]] : ['--:--', '--:--'],
    },
    {
      day: 'friday',
      morning: time?.length > 0 ? [time[16], time[17]] : ['--:--', '--:--'],
      afternoon: time?.length > 0 ? [time[18], time[19]] : ['--:--', '--:--'],
    },
    {
      day: 'saturday',
      morning: time?.length > 0 ? [time[20], time[21]] : ['--:--', '--:--'],
      afternoon: time?.length > 0 ? [time[22], time[23]] : ['--:--', '--:--'],
    },
  ];

  return (
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>{intl.formatMessage(messages.day)}</th>
          <th>{intl.formatMessage(messages.morning)}</th>
          <th>{intl.formatMessage(messages.afternoon)}</th>
        </tr>
      </thead>
      <tbody>
        {timeSlots?.map((slot, index) => (
          <tr key={index}>
            <td>{intl.formatMessage(messages[slot.day])}</td>
            <td>
              {slot.morning !== '--:--' && (
                <>
                  {intl.formatMessage(messages.from_time)} {slot.morning[0]}
                  {' - '}
                  {intl.formatMessage(messages.to_time)} {slot.morning[1]}
                </>
              )}
            </td>
            <td>
              {slot.afternoon !== '--:--' && (
                <>
                  {intl.formatMessage(messages.from_time)} {slot.afternoon[0]}
                  {' - '}
                  {intl.formatMessage(messages.to_time)} {slot.afternoon[1]}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

/**
 * View Accordion block class.
 * @class View
 * @extends Component
 */
const PNOfficeDates = ({ content, block }) => {
  const intl = useIntl();

  return richTextHasContent(content?.openTimeDetails) ? (
    <RichTextSection
      tag_id={'title-office-time'}
      title={intl.formatMessage(messages.time)}
    >
      <Card
        className="card-bg no-after rounded my-3"
        noWrapper={false}
        tag="div"
      >
        <CardBody tag="div">
          <OfficeTimeTable time={content?.time} />
          <RichTextSection
            data={content.openTimeDetails}
            tag_id={'title-openTime'}
            field="text"
          />
        </CardBody>
      </Card>
    </RichTextSection>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNOfficeDates.propTypes = {
  content: PropTypes.shape({
    openTimeDetails: PropTypes.object,
  }),
  block: PropTypes.object,
};

export default PNOfficeDates;
