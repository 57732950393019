/**
 * Activities component.
 * @module components/Features/MyArea/Components/Activities
 *
 * Page which displays personal activities page.
 *
 */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'design-react-kit';
import { FileWidget, PaymentWidget } from '@tecnoteca/volto-cmdbuild/formwidgets';
import { defineMessages, useIntl } from 'react-intl';
import {
  makePagoPARequest,
  updatePayment,
} from '@tecnoteca/volto-cmdbuild/proxies';
import { useEffect, useState } from 'react';

import { Pagination } from 'design-comuni-plone-theme/components/ItaliaTheme/';
import PropTypes from 'prop-types';
import { expandToBackendURL } from '@plone/volto/helpers';
import { formatDate } from '@plone/volto/helpers/Utils/Date';
import { useViewsModel } from '@tecnoteca/volto-cmdbuild/modelsuses';

const Activities = ({ limit, paymentStatus }) => {
  const intl = useIntl();
  const { getItems } = useViewsModel();
  const viewName = 'LastActivities';
  const [collapseElementOpen, setCollapseElement] = useState('');
  const [items, setItems] = useState();
  const [currentPage, setCurrentPage] = useState(undefined);
  const [totalItems, setTotalItems] = useState(items?.length);

  const [modalHeader, setModalHeader] = useState();
  const [modalBody, setModalBody] = useState();
  const [modalSize, setModalSize] = useState('lg');

  const messages = defineMessages({
    practice: {
      id: 'practice',
      defaultMessage: 'Pratica: ',
    },
    noActivities: {
      id: 'noActivities',
      defaultMessage: 'Al momento non ci sono attività',
    },
  });
  limit = limit || 10;
  let config = {
    sort: [
      {
        property: 'Date',
        direction: 'DESC',
      },
    ],
    limit: limit || 10,
    page: currentPage,
    start: (currentPage - 1) * limit,
  };

  useEffect(() => {
    // check for payment status
    if (paymentStatus === 'success') {
      setModalHeader('Pagamento');
      setModalBody(
        <div className="align-middle">
          <p className="mb-3">Il tuo pagamento è avvenuto correttamente.</p>
        </div>,
      );
      setModalSize('sm');
      setModalOpen(true);
    } else if (paymentStatus === 'failure') {
      setModalHeader('Pagamento');
      setModalBody(
        <div className="align-middle">
          <p className="mb-3">Si sono verificati dei problemi durante il pagamento.</p>
        </div>,
      );
      setModalSize('sm');
      setModalOpen(true);
    }
  }, [paymentStatus]);

  useEffect(() => {
    loadItems(currentPage);
  }, [currentPage]);

  const loadItems = () => {
    getItems(viewName, config).then(({ data, meta }) => {
      setItems(
        data?.map((i) => {
          i.documenti = JSON.parse(i.Documenti);
          return i;
        }),
      );
      setTotalItems(meta?.total);
    });
    config = {
      ...config,
      start: (currentPage - 1) * limit,
    };
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage?.children);
  };
  const totalPages = Math.ceil(totalItems / config?.limit);
  const [isModalOpen, setModalOpen] = useState(false);

  const onButtonClick = (process, item) => {
    if (item.type === 'report') {
      setModalHeader(item.description);
      setModalBody(
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          src={expandToBackendURL(
            `@c_get_file/reports/` +
              item?.code +
              `/` +
              item?.code +
              `.pdf?extension=pdf&parameters=` +
              JSON.stringify({
                process_id: process['Id richiesta'],
              }),
          )}
          data-ref="iframeEl"
          width="100%"
          height={window.innerHeight * 0.8}
          frameborder="0"
        ></iframe>,
      );
      setModalSize('lg');
      setModalOpen(true);
    } else if (item.type === 'pagopa_pagamento') {
      setModalHeader(item.description);
      setModalBody(
        <PaymentWidget
          widget={{
            IUV: item.iuv,
            LinkAttribute: item.link_att,
          }}
          context={{
            _id: process['Id richiesta'],
            _type: process.Tipo,
          }}
        />,
      );
      setModalSize('sm');
      setModalOpen(true);
    } else if (
      item.type === 'pagopa_avviso' ||
      item.type === 'pagopa_ricevuta'
    ) {
      let widgetconfig = {};
      if (item.type === 'pagopa_avviso') {
        widgetconfig.Endpoint = `PagoPAScaricaAvviso?iuv=${item.iuv}`;
      } else if (item.type === 'pagopa_ricevuta') {
        widgetconfig.Endpoint = `PagoPARicevutaPagamento?iuv=${item.iuv}&iud=${item.iud}`;
      }
      setModalHeader(item.description);
      setModalBody(<FileWidget widget={widgetconfig} context={{}} />);
      setModalSize('lg');
      setModalOpen(true);
    } else {
      console.log(item);
    }
  };

  return (
    <>
      {items?.length > 0 ? (
        items?.map((item, index) => (
          <>
            <Accordion key={index}>
              <AccordionItem>
                <AccordionHeader
                  active={collapseElementOpen === index}
                  onToggle={() =>
                    setCollapseElement(
                      collapseElementOpen !== index ? index : '',
                    )
                  }
                >
                  {item?.Servizio} - {item?.Utente}
                  &nbsp;
                </AccordionHeader>
                <div
                  className="text-muted font-weight-light"
                  id="accordion-date"
                >
                  {formatDate({
                    date: item?.Date,
                    format: 'dd-mm-yyyy',
                    locale: 'it-IT',
                  })} - Stato: {item.Stato}
                </div>
                <AccordionBody active={collapseElementOpen === index}>
                  <div>
                    <p>{item?.Descrizione}</p>
                    <p>
                      <b>{intl.formatMessage(messages.practice)}</b>
                      {item?.Numero}
                    </p>
                    {item?.documenti?.length > 0 &&
                      item?.documenti?.map((element, idx) => (
                        <div key={idx} className="mx-0 my-3 row ">
                          <Button
                            className="text-white btn btn-primary col-4 py-2"
                            onClick={() => onButtonClick(item, element)}
                          >
                            {element?.description}
                          </Button>
                        </div>
                      ))}
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </>
        ))
      ) : (
        <p>{intl.formatMessage(messages.noActivities)}</p>
      )}
      {/* PAGINATION FROM DESIGN COMUNI PLONE THEME */}
      {config?.tab === 'ActivitiesPage' && totalPages > 1 ? (
        <Pagination
          defaultActivePage={1}
          activePage={1}
          boundaryRange={0}
          siblingRange={3}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      ) : (
        ''
      )}
      <Modal
        isOpen={isModalOpen}
        toggle={() => setModalOpen(false)}
        labelledBy={`activities-widget-modal`}
        size={modalSize}
      >
        <ModalHeader
          toggle={() => setModalOpen(false)}
          id={`activities-widget-modal`}
        >
          {modalHeader}
        </ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Activities.propTypes = {
  limit: PropTypes.number,
  config: PropTypes.object,
};

export default Activities;
