import React from 'react';
import PropTypes from 'prop-types';
import {
  RichTextSection,
  ContactLink,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';

const PNAdministratorsViewCityCouncil = ({ content }) => {
  return (content?.Administrators?.length > 0 || content?.roles) &&
    (content.viewtype.title === 'Consiglio comunale' ||
      content.viewtype.title === 'Consiglio Comunale') ? (
    <>
      {content?.Administrators.map((item, index) => (
        <>
          <RichTextSection tag_id={index} title={item?.name} show_title={false}>
            <div class="it-list-wrapper">
              <ul className="it-list commission-list p-0">
                <li>
                  <div class="list-item">
                    <div class="it-right-zone align-items-start">
                      <span class="text">
                        <a href={flattenToAppURL(item?.url)}>{item?.name}</a>
                        {item?.roles &&
                          Object.entries(item.roles).map(
                            ([key, value], roleIndex) => (
                              <em key={`role-${roleIndex}`}> {value}</em>
                            ),
                          )}
                      </span>
                      <span className="it-multiple">
                        <span className="metadata align-items-start">
                          <p>
                            <ContactLink email={item?.email} label={false} />
                          </p>
                          <p>
                            <ContactLink tel={item?.phone} label={false} />
                          </p>
                        </span>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </RichTextSection>
        </>
      ))}
    </>
  ) : (
    <></>
  );
};

PNAdministratorsViewCityCouncil.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorsViewCityCouncil;
