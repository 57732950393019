import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Row, CardBody, Icon } from 'design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  textInfo: {
    id: 'textInfo',
    defaultMessage: 'Informazioni',
  },
  secretariat: {
    id: 'secretariat',
    defaultMessage: 'Segreteria',
  },
});

const PNAdministratorsViewSecretariat = ({ content }) => {
  const intl = useIntl();

  return (
    content?._secretariats?.length > 0 && (
      <RichTextSection
        tag_id="secretariat-list"
        title={intl.formatMessage(messages.secretariat)}
        show_title={true}
      >
        <Row className="secretariat-card mt-5 p-0">
          {content?._secretariats.map((secretary, index) => (
            <div class="card-wrapper rounded shadow col-6 col-lg-5 m-1 mb-3">
              <CardBody className="p-2">
                <p>
                  <Icon
                    icon="it-user"
                    title={secretary?.title}
                    size="md"
                    padding={true}
                  />
                  <a
                    href={flattenToAppURL(secretary['@id'])}
                    className="text-nowrap"
                  >
                    {secretary?.title}
                  </a>
                </p>
              </CardBody>
            </div>
          ))}
        </Row>
      </RichTextSection>
    )
  );
};

PNAdministratorsViewSecretariat.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorsViewSecretariat;
