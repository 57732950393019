import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Card, CardBody, CardText, Icon } from 'design-react-kit';

const messages = defineMessages({
  officeResponsible: {
    id: 'officeResponsible: ',
    defaultMessage: 'Responsabile ',
  },
  people: {
    id: 'people: ',
    defaultMessage: 'Personale ',
  },
});

const PNOfficePeople = ({ content }) => {
  const intl = useIntl();
  return content?.officeResponsible || content?.officeStaff ? (
    <>
      {/* RESPONSABILE */}
      {content?.officeResponsible && (
        <RichTextSection
          tag_id={'title-office-responsible'}
          field="text"
          title={intl.formatMessage(messages.officeResponsible)}
          show_title={true}
        >
          <div className="col-lg-6 col-md-10 col-12">
            <Card
              className="card-bg card-teaser rounded p-3"
              noWrapper={false}
              space
              tag="div"
            >
              <CardBody>
                <Icon
                  icon="it-user"
                  title={content.officeResponsible?.title}
                  size="md"
                  padding={true}
                />
                <a
                  href={flattenToAppURL(
                    'resolveuid/' + content?.officeResponsible?.token,
                  )}
                >
                  {content.officeResponsible?.title}
                </a>
                <CardText>{content?.responsibleText}</CardText>
              </CardBody>
            </Card>
          </div>
        </RichTextSection>
      )}
      {/* PERSONE */}
      {content?.officeStaff?.length > 0 && (
        <RichTextSection
          tag_id={'title-office-people'}
          field="text"
          title={intl.formatMessage(messages.people)}
          show_title={true}
          className=" col-lg-6 col-md-10 col-12"
        >
          {content?.officeStaff.map((item, index) => (
            <div className=" col-lg-6 col-md-1 col-12">
              <Card
                className="card-bg card-teaser rounded my-3 p-3"
                noWrapper={true}
                space
                tag="div"
              >
                <CardBody>
                  <Icon
                    icon="it-user"
                    title={item?.title}
                    size="md"
                    padding={true}
                  />
                  <a href={flattenToAppURL('resolveuid/' + item.token)}>
                    {item.title}
                  </a>
                </CardBody>
              </Card>
            </div>
          ))}
        </RichTextSection>
      )}
    </>
  ) : (
    ''
  );
};

PNOfficePeople.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNOfficePeople;
