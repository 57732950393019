/* eslint-disable react-hooks/exhaustive-deps */
/**
 * OnlineService component.
 * @module components/Features/OnlineService/OnlineService
 *
 * Page which displays online service page.
 *
 */
import {
  PagePlaceholderTitle,
  SearchSectionForm,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { useDispatch, useSelector } from 'react-redux';

import ActiveInstancesList from './ActiveInstancesList';
import { Helmet } from '@plone/volto/helpers';
import Privacy from '../Privacy/Privacy';
import { ProcessInstanceForm } from '@tecnoteca/volto-cmdbuild/utilities';
import ServiceDisabledMessage from './ServiceDisabledMessage';
import cx from 'classnames';
import { getFunctionOutputsProxy } from '@tecnoteca/volto-cmdbuild/proxies';
import { getOnlineServiceInfo } from 'comune-pordenone-plone-theme/actions';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const ACTIONS = {
  SHOWINSTANCES: 'showinstances',
  SHOWSERVICEFORM: 'showserviceform',
  SHOWNONACTIVE: 'shownonactive',
};

const OnlineService = (props) => {
  const intl = useIntl();
  const content = props.content;
  const dispatch = useDispatch();
  const location = useLocation();
  const [confirmPriv, setConfirmPriv] = useState(false);

  const [serviceAction, setServiceAction] = useState();
  const [processName, setProcessName] = useState();
  const [serviceInstances, setServiceInstances] = useState([]);
  const [serviceInstanceId, setServiceInstanceId] = useState();
  const [serviceInstanceActivity, setServiceInstanceActivity] = useState();
  const [serviceTiming, setServiceTiming] = useState();

  const serviceInfo = useSelector((state) => state.onlineService);

  useEffect(() => {
    const url = location.pathname.replace(/\/online-service(\/?)$/, '');
    dispatch(getOnlineServiceInfo(url));
  }, []);

  useEffect(() => {
    if (serviceInfo.authorized === false) {
      const redirectUrl = `${
        location.pathname
      }/portal-login?came-from="${encodeURIComponent(location.pathname)}"`;
      window.location.href = redirectUrl;
    }
  }, [serviceInfo.authorized]);

  useEffect(async () => {
    const procName = serviceInfo?.result?.processName;
    if (procName) {
      setProcessName(procName);
      setServiceTiming(serviceInfo.result.timing);

      const e_response = await getFunctionOutputsProxy(
        'wf_cityproc_is_enabled',
        {
          service: procName,
        },
      );

      if (!e_response.data[0].enabled) {
        setServiceAction(ACTIONS.SHOWNONACTIVE);
      } else {
        const response = await getFunctionOutputsProxy(
          'wf_cityproc_data_by_parent_get',
          {
            process_class: procName,
            parent_id: null,
            current_year_id: null,
            before_year: null,
            completed: false,
          },
        );
        const process = JSON.parse(response.data[0].processes);

        if (response.success) {
          const nextaction =
            process?.length > 0
              ? ACTIONS.SHOWINSTANCES
              : ACTIONS.SHOWSERVICEFORM;

          setServiceInstances(process);
          setServiceAction(nextaction);
        } else {
          setServiceAction(ACTIONS.SHOWSERVICEFORM);
        }
      }
    }
  }, [serviceInfo?.result]);

  const showFormForInstance = (row) => {
    setServiceAction(ACTIONS.SHOWSERVICEFORM);
    if (row?.id && row.avtivityId) {
      setConfirmPriv(true);
      setServiceInstanceId(row.id);
      setServiceInstanceActivity(row.avtivityId);
    } else {
      setConfirmPriv(false);
    }
  };

  const resetPage = () => {
    setConfirmPriv(false);
    window.scrollTo({
      top: 200,
      behavior: 'smooth',
    });
  };

  const rightHeaderHasContent = false;
  return (
    <>
      <Helmet title={serviceInfo?.result?.title} />
      <div id="page-document" className="ui container px-4">
        {/*-----Testata-----*/}
        <div className="PageHeaderWrapper mb-4">
          <div className="row">
            <div
              className={cx('title-description-wrapper', {
                'col-lg-6': rightHeaderHasContent,
                'col-lg-12': !rightHeaderHasContent,
              })}
            >
              <PagePlaceholderTitle content={serviceInfo}>
                <h1 className="mb-3" data-element="page-name">
                  {serviceInfo?.result?.title}
                </h1>
              </PagePlaceholderTitle>
              <p className="description">{content?.description}</p>
              {content?.ricerca_in_testata && (
                <SearchSectionForm content={content} />
              )}
            </div>
          </div>
        </div>
        {serviceAction === ACTIONS.SHOWSERVICEFORM ? (
          confirmPriv === false ? (
            <Privacy
              confirmAction={setConfirmPriv}
              processName={processName}
              instanceId={serviceInstanceId}
              activityId={serviceInstanceActivity}
            />
          ) : (
            <ProcessInstanceForm
              processName={processName}
              instanceId={serviceInstanceId}
              activityId={serviceInstanceActivity}
              resetAction={resetPage}
            />
          )
        ) : (
          ''
        )}
        {serviceAction === ACTIONS.SHOWINSTANCES ? (
          <ActiveInstancesList
            instances={serviceInstances}
            action={showFormForInstance}
          />
        ) : (
          ''
        )}
        {serviceAction === ACTIONS.SHOWNONACTIVE ? (
          <ServiceDisabledMessage timing={serviceTiming} />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default OnlineService;
