import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'design-react-kit';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { useSelector } from 'react-redux';

const messages = defineMessages({
  summary: {
    id: 'summary',
    defaultMessage: 'Riepilogo Segnalazione',
  },
  report: {
    id: 'report',
    defaultMessage: 'Segnalazione',
  },
  address: {
    id: 'address',
    defaultMessage: 'Indirizzo',
  },
  issue: {
    id: 'issue',
    defaultMessage: 'Disservizio',
  },
  place: {
    id: 'place',
    defaultMessage: 'Luogo',
  },
  issueType: {
    id: 'issueType',
    defaultMessage: 'Tipo di Disservizio',
  },
  title: {
    id: 'title',
    defaultMessage: 'Titolo',
  },
  details: {
    id: 'details',
    defaultMessage: 'Dettagli',
  },
  images: {
    id: 'images',
    defaultMessage: 'Immagini allegate',
  },
  requester: {
    id: 'requester',
    defaultMessage: 'Richiedente',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Telefono',
  },
  fiscal_number: {
    id: 'fiscal_number',
    defaultMessage: 'Codice Fiscale',
  },
  general_data: {
    id: 'general_data',
    defaultMessage: 'Dati generali',
  },
  issue_author: {
    id: 'issue_author',
    defaultMessage: 'Autore della segnalazione',
  },
  no_data: {
    id: 'no_general_data',
    defaultMessage:
      'Non sono stati inseriti dati. Si prega di completare i campi richiesti per procedere.',
  },
});

const IssueSummary = ({
  currentState,
  images,
  address,
  issueType,
  title,
  details,
  firstName,
  lastName,
  phone,
  email,
}) => {
  const intl = useIntl();
  const userSession = useSelector((state) => state.portalUser);
  const [fullScreenImage, setFullScreenImage] = useState(null);

  const renderImages = () => {
    if (images && images.length > 0) {
      return (
        <Row className="mt-3">
          {images?.map((image, index) => (
            <Col xs={4} key={index}>
              <img
                src={URL.createObjectURL(image)}
                alt={`Image ${index + 1}`}
                className="img-fluid"
                onClick={() => setFullScreenImage(image)}
                style={{ cursor: 'pointer' }}
              />
            </Col>
          ))}
        </Row>
      );
    }
    return null;
  };

  return currentState === 3 ? (
    <RichTextSection
      tag_id={intl.formatMessage(messages.summary)}
      id={intl.formatMessage(messages.summary)}
      title_tag="h4"
    >
      <RichTextSection tag_id="Segnalazione" title_tag="h4">
        <h2>{intl.formatMessage(messages.report)}</h2>
        <Card className="card border rounded shadow-sm mt-4 p-3">
          {address || issueType || title || details || images?.length > 0 ? (
            <>
              <CardHeader className="border-0 p-0 px-4 mb-lg-20 m-0">
                <div className="d-flex">
                  <h3 className="subtitle-large icon-required" id="">
                    {intl.formatMessage(messages.issue)}
                  </h3>
                </div>
              </CardHeader>
              <CardBody>
                {address && (
                  <div className="py-3 border-top border-bottom">
                    <div>{intl.formatMessage(messages.address)}</div>
                    <strong>{address}</strong>
                  </div>
                )}
                {issueType && (
                  <div className="py-3 border-bottom">
                    <div>{intl.formatMessage(messages.issueType)}</div>
                    <strong>{issueType}</strong>
                  </div>
                )}
                {title && (
                  <div className="py-3 border-bottom">
                    <div>{intl.formatMessage(messages.title)}</div>
                    <strong>{title}</strong>
                  </div>
                )}
                {details && (
                  <div className="py-3 border-bottom">
                    <div>{intl.formatMessage(messages.details)}</div>
                    <strong>{details}</strong>
                  </div>
                )}
                {images?.length > 0 && (
                  <div className="py-3 border-bottom">
                    <div>{intl.formatMessage(messages.images)}</div>
                    {renderImages()}
                  </div>
                )}
              </CardBody>
            </>
          ) : (
            <>
              <p className="text-primary">
                {intl.formatMessage(messages.no_data)}
              </p>
            </>
          )}
        </Card>
        <Modal
          isOpen={fullScreenImage !== null}
          toggle={() => setFullScreenImage(null)}
          size="xl"
          centered
        >
          <ModalHeader toggle={() => setFullScreenImage(null)}>
            {intl.formatMessage(messages.images)}
          </ModalHeader>
          <ModalBody className="text-center pb-4">
            {fullScreenImage && (
              <img
                src={URL.createObjectURL(fullScreenImage)}
                alt="Full screen"
                className="img-fluid"
                style={{ maxHeight: '80vh' }}
              />
            )}
          </ModalBody>
        </Modal>
      </RichTextSection>
      <RichTextSection tag_id="Dati generali" title_tag="h4">
        <h2>{intl.formatMessage(messages.general_data)}</h2>
        <Card className="card border rounded shadow-sm mt-4 p-3">
          {userSession?.user?.first_name ||
          userSession?.user?.last_name ||
          userSession.user.fiscal_number ||
          email ||
          phone ? (
            <>
              <CardHeader className="border-0 p-0 px-4 mb-lg-20 m-0">
                <h3 className="subtitle-large icon-required" id="">
                  {intl.formatMessage(messages.issue_author)}
                </h3>
              </CardHeader>
              <CardBody>
                <div className="py-3 border-top border-bottom">
                  <div>{intl.formatMessage(messages.issue_author)}</div>
                  <strong>
                    {userSession.isAuthenticated
                      ? `${userSession?.user?.first_name} ${userSession?.user?.last_name}`
                      : `${firstName} ${lastName}`}
                  </strong>
                </div>
                {userSession.isAuthenticated && (
                  <div className="py-3 border-bottom">
                    <div>{intl.formatMessage(messages.fiscal_number)}</div>
                    <strong> {userSession.user.fiscal_number}</strong>
                  </div>
                )}
                {email && (
                  <div className="py-3 border-bottom">
                    <div>{intl.formatMessage(messages.email)}</div>
                    <strong>{email}</strong>
                  </div>
                )}
                {phone && (
                  <div className="py-3 border-bottom">
                    <div>{intl.formatMessage(messages.phone)}</div>
                    <strong>{phone}</strong>
                  </div>
                )}
              </CardBody>
            </>
          ) : (
            <>
              <p className="text-primary">
                {intl.formatMessage(messages.no_data)}
              </p>
            </>
          )}
        </Card>
      </RichTextSection>
    </RichTextSection>
  ) : (
    <></>
  );
};

IssueSummary.propTypes = {
  issueData: PropTypes.shape({
    address: PropTypes.string,
    issueType: PropTypes.string,
    title: PropTypes.string,
    details: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  userSession: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      fiscal_number: PropTypes.string,
    }),
  }),
};

export default IssueSummary;
