import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';

import { flattenToAppURL } from '@plone/volto/helpers';
import {
  richTextHasContent,
  Metadata,
  HelpBox,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

import { Card, CardBody, CardTitle, Chip, ChipLabel } from 'design-react-kit';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const messages = defineMessages({
  moreInfo: {
    id: 'moreInfo',
    defaultMessage: 'Altre informazioni',
  },
  event_place: {
    id: 'event_place',
    defaultMessage: "Luogo dell'evento",
  },
  event_url: {
    id: 'event_url',
    defaultMessage: "Url dell'evento",
  },
  patronage: {
    id: 'patrocinato_da',
    defaultMessage: 'Patrocinato da Comune di pordenone',
  },
  strutture_politiche: {
    id: 'event_strutture_politiche',
    defaultMessage: 'Strutture politiche coinvolte',
  },
  comune_pordenone: {
    id: 'comune_pordenone',
    defaultMessage: 'Comune di Pordenone',
  },
  luoghi: {
    id: 'luogo',
    defaultMessage: 'Luogo',
  },
  badWeather: {
    id: 'badWeather',
    defaultMessage: 'In caso di brutto tempo',
  },
  label_timing: {
    id: 'label_timing',
    defaultMessage: 'Durata evento',
  },
  sponsor: {
    id: 'sponsor',
    defaultMessage: 'Sponsor',
  },
  intendedFor: {
    id: 'intendedFor',
    defaultMessage: 'A chi è rivolto',
  },
  forChildren: {
    id: 'forChildren',
    defaultMessage: 'Questo evento è destinato ai bambini',
  },
  titleOnline: {
    id: 'titleOnline',
    defaultMessage: 'Disponibilità online',
  },
  isOnline: {
    id: 'isOnline',
    defaultMessage: "L'evento sarà disponibile online",
  },
  isNotOnline: {
    id: 'isNotOnline',
    defaultMessage: "L'evento non sarà disponibile online",
  },
  categories: {
    id: 'categories',
    defaultMessage: 'Categorie: ',
  },
});

const PNEventMoreInformation = ({ content }) => {
  const intl = useIntl();

  return (
    <RichTextSection
      tag_id={'title-moreInfo'}
      title={intl.formatMessage(messages.moreInfo)}
      show_title={true}
    >
      <Metadata content={content}>
        {richTextHasContent(content?.ulteriori_informazioni) && (
          <HelpBox text={content?.ulteriori_informazioni} />
        )}
        {richTextHasContent(content?.more_information) && (
          <RichTextSection data={content.more_information} />
        )}
        {content?.availableLocation?.title ? (
          <>
            <h5>{intl.formatMessage(messages.luoghi)}</h5>
            <Card
              className="card card-teaser shadow p-4 mt-3 rounded attachment"
              noWrapper={true}
              tag="div"
            >
              <Icon
                icon="it-map-marker-circle"
                alt={intl.formatMessage(messages.luoghi)}
                title={intl.formatMessage(messages.luoghi)}
              />
              <CardBody tag="div">
                <CardTitle tag="h5" color="red">
                  {content.availableLocation.title}
                </CardTitle>
              </CardBody>
            </Card>
          </>
        ) : (
          content?.location && (
            <>
              <h5>{intl.formatMessage(messages.luoghi)}</h5>
              <Card
                className="card card-teaser shadow p-4 mt-3 rounded attachment"
                noWrapper={true}
                tag="div"
              >
                <Icon
                  icon="it-map-marker-circle"
                  alt={intl.formatMessage(messages.luoghi)}
                  title={intl.formatMessage(messages.luoghi)}
                />
                <CardBody tag="div">
                  <CardTitle tag="h5" color="red">
                    {content.location}
                  </CardTitle>
                </CardBody>
              </Card>
            </>
          )
        )}

        {richTextHasContent(content?.timing) && (
          <div className="mt-4">
            <h5>{intl.formatMessage(messages.label_timing)}</h5>
            <RichTextSection
              title_size="h5"
              title={intl.formatMessage(messages.label_timing)}
              show_title={false}
              data={content?.timing}
            />
          </div>
        )}

        {content?.event_url && (
          <div className="mt-4">
            <h5>{intl.formatMessage(messages.event_url)}</h5>
            <a href={content.event_url}>{flattenToAppURL(content.event_url)}</a>
          </div>
        )}
        {richTextHasContent(content?.patronage) && (
          <div className="mt-4">
            <h5>{intl.formatMessage(messages.patronage)}</h5>
            <RichTextSection
              title_size="h5"
              data={content?.patronage}
              title={intl.formatMessage(messages.patronage)}
              show_title={false}
            />
          </div>
        )}
        {content?.forChildren?.title === 'Sì' && (
          <div className="mt-4">{intl.formatMessage(messages.forChildren)}</div>
        )}
        {content?.isOnline?.title === 'Sì' && (
          <div className="mt-4">
            <h5>{intl.formatMessage(messages.titleOnline)}</h5>
            <p>{intl.formatMessage(messages.isOnline)}</p>
            <ul>
              {content?.online_event_links.map((link, index) => (
                <li>
                  <a href={flattenToAppURL(link)}>{link}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {content?.isOnline?.title === 'No' && (
          <div className="mt-4">
            <h5>{intl.formatMessage(messages.titleOnline)}</h5>
            {intl.formatMessage(messages.isNotOnline)}
          </div>
        )}
        {content?.badWeather && (
          <div className="mt-4">
            <h5>{intl.formatMessage(messages.badWeather)}</h5>
            {content.badWeather}
          </div>
        )}
        {richTextHasContent(content?.sponsor) && (
          <div className="mt-4">
            <h5 className="mt-4">{intl.formatMessage(messages.sponsor)}</h5>
            <RichTextSection
              data={content.sponsor}
              title={intl.formatMessage(messages.sponsor)}
              show_title={false}
            />
          </div>
        )}
        {content?.subjects?.length > 0 && (
          <>
            <h6 className="mt-4">{intl.formatMessage(messages.categories)}</h6>
            <div className="d-flex flex-row flex-wrap justify-content-start">
              {content?.subjects?.map((subject, index) => (
                <div>
                  <Chip
                    id={subject + '_' + index}
                    color="primary"
                    disabled={false}
                    large={false}
                    simple
                    tag="div"
                    className="m-1 text-decoration-none d-flex"
                  >
                    <ChipLabel tag="span">{subject}</ChipLabel>
                  </Chip>
                </div>
              ))}
            </div>
          </>
        )}
      </Metadata>
    </RichTextSection>
  );
};

PNEventMoreInformation.propTypes = {
  content: PropTypes.shape({
    ulteriori_informazioni: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
  }).isRequired,
};

export default PNEventMoreInformation;
