import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SkipToMainContent,
  PageHeader,
  RelatedItems,
  Metadata,
  ContentTypeViewSections,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { PNAlertBehavior } from 'comune-pordenone-plone-theme/components/View';
import {
  Container,
  Col,
  Row,
  Accordion,
  AccordionHeader,
  Progress,
  AccordionBody,
} from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import cx from 'classnames';

import PNAdministratorsViewPrologue from './PNAdministratorsViewPrologue';
import PNAdministratorsViewEpilogue from './PNAdministratorsViewEpilogue';
import PNAdministratorsViewCityBoard from './PNAdministratorsViewCityBoard';
import PNAdministratorsViewCityCouncil from './PNAdministratorsViewCityCouncil';
import PNAdministratorsViewBoardCommittee from './PNAdministratorsViewBoardCommittee';
import PNAdministratorsViewSecretariat from './PNAdministratorsViewSecretariat';

export const AdministratorsViewSectionsOrder = [
  { /* CITY BOARD */ component: PNAdministratorsViewCityBoard },
  { /* CITY COUNCIL */ component: PNAdministratorsViewCityCouncil },
  { /* BOARD COMMITTEE */ component: PNAdministratorsViewBoardCommittee },
];
const messages = defineMessages({
  index: {
    id: 'index',
    defaultMessage: 'Indice della pagina',
  },
});

const PNAdministratorsView = ({ content }) => {
  const intl = useIntl();
  let documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [progressValue, setProgressValue] = useState(0);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    if (documentBody.current && __CLIENT__) {
      setSideMenuElements(documentBody.current);
    }
  }, [documentBody]);

  useEffect(() => {
    if (documentBody.current && typeof window !== 'undefined') {
      setSideMenuElements(documentBody.current);
    }
  }, [documentBody]);

  useEffect(() => {
    if (sideMenuElements) {
      const items = Array.from(sideMenuElements?.children).map((section) => ({
        title: section?.getAttribute('menu_title'),
        id: section?.id,
      }));
      setMenuItems(items);
    }
  }, [sideMenuElements]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / scrollHeight) * 100;
      setProgressValue(scrollPercentage);
    };

    document.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = (sectionId) => {
    setActiveSection(sectionId);
  };

  return (
    <Container className="px-md-8 col-sm-10 col-lg-8 container-administrator">
      <div className="px-4 px-lg-0">
        <SkipToMainContent />
        <PageHeader
          content={content}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
          className="px-md-4"
        />
        <Row className="border-top row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            {sideMenuElements && typeof window !== 'undefined' && (
              <div className="sticky-wrapper navbar-wrapper page-side-menu">
                <nav className="navbar it-navscroll-wrapper navbar-expand-lg">
                  <div className="menu-wrapper">
                    <div className="link-list-wrapper menu-link-list">
                      <div className="accordion-wrapper">
                        <Accordion>
                          <AccordionHeader
                            active={isNavOpen}
                            onToggle={() => {
                              setIsNavOpen(!isNavOpen);
                            }}
                            aria-controls="side-menu-body"
                          >
                            <h3>{intl.formatMessage(messages.index)}</h3>
                          </AccordionHeader>
                          <div className="mb-3">
                            <Progress
                              value={progressValue}
                              role="progressbar"
                            />
                          </div>
                          <AccordionBody
                            active={isNavOpen}
                            id="side-menu-body"
                            role="region"
                          >
                            <ul className="link-list" data-element="page-index">
                              {menuItems?.map((section) => (
                                <>
                                  {section?.id && section?.title && (
                                    <li
                                      className="nav-item"
                                      key={`menu-${section.id}`}
                                    >
                                      <a
                                        href={`#${section?.id}`}
                                        key={section?.id}
                                        className={cx('', {
                                          active: section.id === activeSection,
                                        })}
                                        onClick={() =>
                                          handleMenuClick(section.id)
                                        }
                                      >
                                        {section?.title}
                                      </a>
                                    </li>
                                  )}
                                </>
                              ))}
                            </ul>
                          </AccordionBody>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            )}
          </Col>
          <section
            ref={documentBody}
            id="administratorsView-main-content-section"
            className="col-lg-8 it-page-sections-container"
          >
            {/* SEZIONI */}
            <PNAdministratorsViewPrologue content={content} className="mb-5" />
            <ContentTypeViewSections
              content={content}
              defaultSections={AdministratorsViewSectionsOrder}
            />
            <PNAdministratorsViewSecretariat content={content} />
            <PNAdministratorsViewEpilogue content={content} className="mb-5" />
            <Metadata content={content} title={' '} />
          </section>
        </Row>
        <RelatedItems list={content?.relatedItems} />
      </div>
    </Container>
  );
};

PNAdministratorsView.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }),
};

export default PNAdministratorsView;
