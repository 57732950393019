/**
 * ReservationStepper view component.
 * @module components/View/ReservationStepper
 */

import PropTypes from 'prop-types';
import {
  StepperContainer,
  StepperHeader,
  StepperHeaderElement,
} from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  palce: {
    id: 'palce',
    defaultMessage: 'Luogo',
  },
  time: {
    id: 'time',
    defaultMessage: 'Data e orario',
  },
  details: {
    id: 'details',
    defaultMessage: 'Dettagli appuntamento',
  },
  requester: {
    id: 'requester',
    defaultMessage: 'Richiedente',
  },
  summary: {
    id: 'summary',
    defaultMessage: 'Riepilogo',
  },
});
/**
 * ReservationStepper view component class.
 * @function ReservationStepper
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ReservationStepper = ({ content, currentState, setCurrentState }) => {
  const intl = useIntl();
  const processStatesList = [
    { Description: intl.formatMessage(messages.time), Code: 1 },
    { Description: intl.formatMessage(messages.details), Code: 2 },
    { Description: intl.formatMessage(messages.requester), Code: 3 },
    { Description: intl.formatMessage(messages.summary), Code: 4 },
  ];

  if (!currentState) {
    setCurrentState(1);
  }

  return currentState < 6 ? (
    <>
      <StepperContainer className="steppers">
        <StepperHeader className="steppers-header">
          {processStatesList &&
            processStatesList.map((step) => (
              <StepperHeaderElement
                key={step.Code}
                prependIcon={
                  currentState && currentState > step.Code ? 'it-check' : ''
                }
                variant={
                  currentState
                    ? currentState === step.Code
                      ? 'active'
                      : currentState > step.Code
                      ? 'confirmed'
                      : ''
                    : ''
                }
              >
                {currentState ? (
                  currentState === step.Code ? (
                    <span className="visually-hidden">Attivo</span>
                  ) : currentState > step.Code ? (
                    <span className="visually-hidden">Confermato</span>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {step.Description}
              </StepperHeaderElement>
            ))}
        </StepperHeader>
      </StepperContainer>
    </>
  ) : (
    <></>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ReservationStepper.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default ReservationStepper;
