/**
 * IssueStepper view component.
 * @module components/View/IssueStepper
 */

import PropTypes from 'prop-types';
import {
  StepperContainer,
  StepperHeader,
  StepperHeaderElement,
} from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  privacy: {
    id: 'privacy',
    defaultMessage: 'Autorizzazioni e condizioni',
  },
  data_issue: {
    id: 'data_issue',
    defaultMessage: 'Dati di segnalazione',
  },
  summary: {
    id: 'summary',
    defaultMessage: 'Riepilogo',
  },
  active: {
    id: 'active',
    defaultMessage: 'Attivo',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Confirm',
  },
});
/**
 * IssueStepper view component class.
 * @function IssueStepper
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const IssueStepper = ({ currentState, setCurrentState }) => {
  const intl = useIntl();
  const issueStatesList = [
    { Description: intl.formatMessage(messages.privacy), Code: 1 },
    { Description: intl.formatMessage(messages.data_issue), Code: 2 },
    { Description: intl.formatMessage(messages.summary), Code: 3 },
  ];

  if (!currentState) {
    setCurrentState(1);
  }

  return currentState < 4 ? (
    <>
      <StepperContainer className="steppers">
        <StepperHeader className="steppers-header">
          {issueStatesList &&
            issueStatesList.map((step) => (
              <StepperHeaderElement
                key={step.Code}
                prependIcon={
                  currentState && currentState > step.Code ? 'it-check' : ''
                }
                variant={
                  currentState
                    ? currentState === step.Code
                      ? 'active'
                      : currentState > step.Code
                      ? 'confirmed'
                      : ''
                    : ''
                }
              >
                {currentState ? (
                  currentState === step.Code ? (
                    <span className="visually-hidden">
                      {intl.formatMessage(messages.active)}
                    </span>
                  ) : currentState > step.Code ? (
                    <span className="visually-hidden">
                      {intl.formatMessage(messages.confirm)}
                    </span>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {step.Description}
              </StepperHeaderElement>
            ))}
        </StepperHeader>
      </StepperContainer>
    </>
  ) : (
    <></>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IssueStepper.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default IssueStepper;
