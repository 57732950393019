import { Card, CardBody, CardTitle } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import PropTypes from 'prop-types';
import React from 'react';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  attachments: {
    id: 'attachments',
    defaultMessage: 'Allegati',
  },
  attachment: {
    id: 'attachment',
    defaultMessage: 'Allegato',
  },
  attachment_description: {
    id: 'attachment_description',
    defaultMessage:
      'Documenti pubblicati ai sensi del decreto legislativo 33/2013 e delle norme in materia di trasparenza amministrativa.',
  },
});

const PNAttachments = ({ content }) => {
  const intl = useIntl();
  const attachments = content?.items;

  const renderItems = attachments?.filter(
    (attachments) =>
      attachments['@type'] === 'PNEventFile' ||
      attachments['@type'] === 'PNNewsFile' ||
      attachments['@type'] === 'PNServiceFile' ||
      attachments['@type'] === 'PNPersonFile' ||
      attachments['@type'] === 'PNOfficeAttachment' ||
      attachments['@type'] === 'PNModule' ||
      attachments['@type'] === 'PNMultipleModule' ||
      attachments['@type'] === 'File',
  );

  return renderItems?.length > 0 ? (
    <RichTextSection
      tag_id="attachments_view"
      title={intl.formatMessage(messages.attachments)}
    >
      {content['@type'] !== 'PNNewsItem' && (
        <p>{intl.formatMessage(messages.attachment_description)}</p>
      )}
      {renderItems?.map((item, index) => {
        const itemURL = `${item['@id']}`;

        return (
          <Card
            key={index}
            className="card card-teaser shadow p-4 mt-3 rounded attachment"
            noWrapper={true}
            tag="div"
          >
            <Icon
              icon="it-clip"
              alt={intl.formatMessage(messages.attachment)}
              title={intl.formatMessage(messages.attachment)}
            />
            <CardBody tag="div">
              <CardTitle tag="h5">
                <a href={flattenToAppURL(itemURL)} data-element="service-file">
                  {item?.title}
                </a>
              </CardTitle>
              {item?.description && <p>{item?.description}</p>}
            </CardBody>
          </Card>
        );
      })}
    </RichTextSection>
  ) : (
    ''
  );
};

PNAttachments.propTypes = {
  content: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
};

export default PNAttachments;
