/**
 * IssuePrivacy view component.
 * @module components/View/IssuePrivacy
 */
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import React, { useState } from 'react';
import { Input, Label, Row, Button } from 'design-react-kit';

const messages = defineMessages({
  descriptionAgenda: {
    id: 'descriptionAgenda',
    defaultMessage: 'Descrizione agenda',
  },
  privacyDescription: {
    id: 'privacyDescription',
    defaultMessage:
      "Il Comune di Pordenone gestisce i dati personali forniti e liberamente comunicati sulla base dell'articolo 13 del Regolamento (UE) 2016/679 General data protection regulation (Gdpr) e degli articoli 13 e successive modifiche e integrazione del decreto legislativo (di seguito d.lgs) 267/2000.",
  },
  privacyReadTerms: {
    id: 'privacyReadTerms',
    defaultMessage:
      "Per i dettagli sul trattamento dei dati personali consulta l'",
  },
  privacyText: {
    id: 'privacyText',
    defaultMessage: "Ho letto e compreso l'informativa sulla privacy",
  },
  privacyLinkText: {
    id: 'privacyLinkText',
    defaultMessage: 'informativa sulla privacy.',
  },
  avanti: {
    id: 'avanti',
    defaultMessage: 'Avanti',
  },
  text_error_check: {
    id: 'text_error_check',
    defaultMessage: `* Prima di procedere accettare l'informativa sulla privacy.`,
  },
});

/**
 * IssuePrivacy view component class.
 * @function IssuePrivacy
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const IssuePrivacy = ({
  checked,
  setChecked,
  currentState,
  setCurrentState,
}) => {
  const intl = useIntl();
  const [showError, setShowError] = useState(false);
  const [inputChecked, setInputChecked] = useState(false);
  const linkTerms = 'https://pordenone.tecnotools.it/it/informativa-privacy';

  const handleCheckboxChange = () => {
    setInputChecked((inputChecked) => !inputChecked);
    setShowError(false);
  };

  return (
    <>
      <RichTextSection>
        <Row className="justify-content-center">
          <div className="col-12 col-lg-8 pb-40 pb-lg-80">
            <p className="text-paragraph mb-lg-4">
              {intl.formatMessage(messages.privacyDescription)}
            </p>
            <p className="text-paragraph mb-0">
              {intl.formatMessage(messages.privacyReadTerms)}
              <a
                href={linkTerms}
                rel="noreferrer"
                target="_blank"
                className="t-primary"
              >
                {intl.formatMessage(messages.privacyText)}
              </a>
            </p>

            <div className="form-check mt-4 mb-3 mt-md-40 mb-lg-40">
              <div className="checkbox-body d-flex align-items-center">
                <Input
                  type="checkbox"
                  id={'checkbox-privacy'}
                  required={true}
                  onClick={handleCheckboxChange}
                />
                <Label
                  check
                  for={'checkbox-privacy'}
                  className="title-small-semi-bold pt-1"
                >
                  {intl.formatMessage(messages.privacyText)}
                </Label>
              </div>
              <Label
                className={`title-small-semi-bold danger text-primary fs-6 ${
                  showError ? '' : 'invisible'
                }`}
                id="checkErrorText"
                for={'checkbox-privacy'}
              >
                {intl.formatMessage(messages.text_error_check)}
              </Label>
            </div>
          </div>
        </Row>
        <Row className="justify-content-center">
          <div className="col-12 col-lg-8 pb-40 pb-lg-80">
            <div className="bg-grey-card shadow-contacts mb-4">
              <Row>
                <div className="p-contacts justify-content-start">
                  <Button
                    type="button"
                    className="btn btn-primary mobile-full col-12 col-lg-6"
                    onClick={() => {
                      if (inputChecked) {
                        setChecked(!checked);
                        setCurrentState(currentState + 1);
                      } else {
                        setShowError(true);
                      }
                    }}
                  >
                    <span className="text-white">
                      {intl.formatMessage(messages.avanti)}
                    </span>
                  </Button>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </RichTextSection>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IssuePrivacy.propTypes = {
  content: PropTypes.shape({}),
};

export default IssuePrivacy;
