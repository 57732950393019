/**
 * ReservationStepperNav view component.
 * @module components/View/ReservationStepperNav
 */

import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  StepperContainer,
  StepperNav,
  Row,
} from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'design-react-kit';
import { useDispatch, useSelector } from 'react-redux';
import { expandToBackendURL } from '@plone/volto/helpers';
import {
  createBooking,
  getBookings,
} from 'comune-pordenone-plone-theme/actions';

const messages = defineMessages({
  officeDescription: {
    id: 'officeDescription',
    defaultMessage: "Scegli l'ufficio a cui vuoi richiedere l'appuntamento",
  },
  officeType: {
    id: 'officeType',
    defaultMessage: 'Tipologia di ufficio',
  },
  go_back: {
    id: 'go_back',
    defaultMessage: 'Indietro',
  },
  save_request: {
    id: 'save_request',
    defaultMessage: 'Salva richiesta',
  },
  advance: {
    id: 'advance',
    defaultMessage: 'Avanti',
  },
  infoPrivacy: {
    id: 'infoPrivacy',
    defaultMessage: 'Informativa privacy',
  },
  summary: {
    id: 'summary',
    defaultMessage: 'Riepilogo',
  },
  office: {
    id: 'office',
    defaultMessage: 'Ufficio',
  },
  time: {
    id: 'time',
    defaultMessage: 'Data e orario',
  },
  confirmDescription: {
    id: 'confirmDescription',
    defaultMessage:
      'Cliccando su Conferma e invia confermi di aver preso visione dei termini e delle condizioni di servizio.',
  },
  terms: {
    id: 'terms',
    defaultMessage: 'Termini e condizioni',
  },
  readTerms: {
    id: 'readTerms',
    defaultMessage: 'Leggi termini e condizioni',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Conferma e invia',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Annulla',
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'Nome',
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Cognome',
  },
  fiscalNumber: {
    id: 'fiscalNumber',
    defaultMessage: 'Codice fiscale',
  },
  email: {
    id: 'email',
    defaultMessage: 'E-mail',
  },
  reservation: {
    id: 'reservation',
    defaultMessage: 'Prenotazione',
  },
  validateFields: {
    id: 'validateFields',
    defaultMessage: `Si prega di compilare tutti i campi obbligatori al fine di procedere con l'invio della segnalazione`,
  },
});

/**
 * ReservationStepperNav view component class.
 * @function ReservationStepperNav
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ReservationStepperNav = ({
  content,
  currentState,
  setCurrentState,
  selectedAppointment,
  selectedType,
  requesterData,
  setCreateResponse,
  messageError,
  setMessageError,
  formError,
  setFormError,
  showFormError,
  setShowFormError,
  isOpen,
  toggleModal,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [formattedRequester, setFormattedRequester] = useState('');
  const userSession = useSelector((state) => state.portalUser);
  const createBookingState = useSelector((state) => state.createBooking);
  const linkTerms = 'https://pordenone.tecnotools.it/it/informativa-privacy';
  const baseURL = expandToBackendURL(content?.[`@id`]);

  /**
   * Formats requester data (name, email, etc.) whenever requesterData or authentication status changes.
   * Updates formattedRequester state for use in reservation submission.
   */
  useEffect(() => {
    setFormattedRequester([
      {
        name: 'title',
        value: `${intl.formatMessage(messages.reservation)} - ${
          requesterData[intl.formatMessage(messages.firstName)]
        } ${requesterData[intl.formatMessage(messages.lastName)]} - ${
          selectedType?.name
        }`,
      },
      {
        name: 'fullname',
        value: `${requesterData[intl.formatMessage(messages.firstName)]} ${
          requesterData[intl.formatMessage(messages.lastName)]
        }`,
      },
      {
        name: 'email',
        value: requesterData[intl.formatMessage(messages.email)],
      },
      userSession?.isAuthenticated && {
        name: 'fiscal_code',
        value: requesterData[intl.formatMessage(messages.fiscalNumber)],
      },
    ]);
  }, [requesterData, userSession?.isAuthenticated]);

  /**
   * Handles reservation creation by dispatching createBooking action.
   * Updates loading state and handles errors or successful responses.
   */
  const createReservation = async () => {
    setMessageError('');
    setIsLoading(false);
    try {
      const response = await dispatch(
        createBooking(baseURL, {
          booking_date: selectedAppointment,
          booking_type: selectedType?.name,
          fields: formattedRequester,
        }),
      );
      setCreateResponse(response);
      toggleModal(false);
      window.scrollTo({ top: 480, behavior: 'smooth' });
    } catch (error) {
      console.error('Error creating reservation:', error);
      setMessageError(error?.response?.body?.message);
    }
  };

  /**
   * Watches createBookingState for changes and manages state transitions,
   * including loading, error, or success updates.
   */
  useEffect(() => {
    setIsLoading(false);
    if (createBookingState?.hasError === true) {
      setMessageError(createBookingState?.error?.response?.body?.message);
    } else if (
      createBookingState?.loading === false &&
      createBookingState?.hasError === false &&
      Object.keys(createBookingState?.result)?.length > 0
    ) {
      setIsLoading(false);
      setCurrentState(currentState + 1);
      toggleModal(false);
    } else if (createBookingState?.loading === true) {
      setIsLoading(true);
    }
  }, [createBookingState]);

  /**
   * Dispatches action to retrieve existing reservations.
   */
  useEffect(() => {
    dispatch(getBookings(baseURL));
  }, [baseURL]);

  /**
   * Navigates to the previous step in the reservation process.
   * Clears error messages and scrolls to a specific view position.
   */
  const handleBackClick = () => {
    setMessageError('');
    window.scrollTo({ top: 550, behavior: 'smooth' });
    setCurrentState(currentState - 1);
  };

  /**
   * Advances to the next step in the reservation process.
   * Shows form errors if validation fails; otherwise, proceeds to next step or opens modal if on final step.
   */
  const handleNextClick = () => {
    if (formError) {
      setShowFormError(true);
    } else if (currentState === 4) {
      toggleModal((isOpen) => !isOpen);
    } else {
      window.scrollTo({ top: 550, behavior: 'smooth' });
      setFormError(false);
      setShowFormError(false);
      setCurrentState(currentState + 1);
      setFormError(true);
      setShowFormError(null);
    }
  };

  /**
   * Handles reservation confirmation from the modal, calling createReservation to submit the form.
   */
  const handleConfirm = async () => {
    createReservation();
    toggleModal((isOpen) => !isOpen);
  };

  if (!currentState) {
    setCurrentState(1);
  }

  return currentState < 5 ? (
    <>
      <StepperContainer>
        <div className="cmp-nav-steps">
          {messageError && (
            <div className="text-center border border-danger col-12 p-2">
              {messageError}
            </div>
          )}
          {showFormError && (
            <div className="text-center border border-danger col-12 p-2">
              {intl.formatMessage(messages.validateFields)}
            </div>
          )}
          <StepperNav>
            {/* BUTTON GO BACK*/}
            <Button
              type="button"
              className="btn btn-outline-primary bg-white btn-sm steppers-btn-save d-none d-lg-block btn-back-step"
              data-element="btn-back"
              onClick={handleBackClick}
              disabled={currentState === 1}
            >
              <span className="text-button-sm t-primary">
                {intl.formatMessage(messages.go_back)}
              </span>
            </Button>
            {/* BUTTON ADVANCE*/}
            <Button
              type="button"
              className="btn btn-primary btn-sm stepper-btn-next d-flex justify-content-center align-items-center"
              style={{ height: '48px' }}
              data-bs-toggle="modal"
              data-bs-target="#modal-save-1"
              data-focus-mouse="false"
              data-element="advance"
              onClick={handleNextClick}
            >
              <span className="text-button-sm text-white flex-fill">
                {intl.formatMessage(messages.advance)}
              </span>
              <Icon icon="it-chevron-right" title="Avanti" />
            </Button>
          </StepperNav>
        </div>
      </StepperContainer>
      <div style={{ marginBottom: '2rem' }}>
        <Modal
          isOpen={isOpen}
          labelledBy={`widget-modal-${content.title}`}
          size="md"
          className="col-12"
        >
          <ModalHeader
            id={`widget-modal-header-${content.title}`}
            className="position-relative"
          >
            <strong>
              <h5>{intl.formatMessage(messages.terms)}</h5>
            </strong>
            <Button
              className="position-absolute end-0 top-0 m-2"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Chiudi finestra modale"
              onClick={() => {
                toggleModal(!isOpen);
              }}
            >
              <Icon
                className=""
                color="primary"
                icon="it-close-big"
                size=""
                title="close"
              />
            </Button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <a href={linkTerms} target="_blank" rel="noreferrer">
                {intl.formatMessage(messages.readTerms)}
              </a>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-5 d-flex justify-content-center">
            {/* CONFIRM */}
            {isLoading ? (
              <Spinner className="" active small />
            ) : (
              <Button
                color="primary"
                className="btn btn-primary col-12 text-white"
                onClick={handleConfirm}
              >
                {intl.formatMessage(messages.confirm)}
              </Button>
            )}
            {/* CANCEL */}
            <Button
              className="col-12 btn btn-outline-primary bg-white"
              onClick={() => {
                toggleModal((isOpen) => !isOpen);
                setMessageError('');
              }}
            >
              {intl.formatMessage(messages.cancel)}
            </Button>
            {messageError && (
              <div className="callout danger col-12">
                <div className="callout-inner">
                  <div className="callout-title">
                    <span className="text">
                      <Icon icon={'it-error'} />
                    </span>
                  </div>
                  <p>{messageError}</p>
                </div>
              </div>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </>
  ) : (
    <></>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ReservationStepperNav.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default ReservationStepperNav;
