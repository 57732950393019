import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Card, CardBody, CardTitle, Icon } from 'design-react-kit';

const messages = defineMessages({
  mainLocation: {
    id: 'mainLocation',
    defaultMessage: 'Sede principale',
  },
  otherLocations: {
    id: 'otherLocations',
    defaultMessage: 'Altre sedi',
  },
});

const PNOfficeLocations = ({ content }) => {
  const intl = useIntl();

  return content?.main_site ? (
    <>
      {/* At the moment static view, update it with locations vocabulary who has to be created on the control panel */}
      <RichTextSection
        tag_id={'office-mainLocation'}
        field="text"
        title={intl.formatMessage(messages.mainLocation)}
        show_title={true}
      >
        <Card
          className="card card-teaser shadow p-4 mt-3 rounded attachment"
          noWrapper={true}
          tag="div"
        >
          <Icon
            icon="it-map-marker-circle"
            alt={intl.formatMessage(messages.mainLocation)}
            title={intl.formatMessage(messages.mainLocation)}
          />
          <CardBody tag="div">
            <CardTitle tag="h5" color="red">
              {content?.main_site?.title}
            </CardTitle>
          </CardBody>
        </Card>
      </RichTextSection>
    </>
  ) : (
    ''
  );
};

PNOfficeLocations.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNOfficeLocations;
