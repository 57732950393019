/**
 * ReservationTime view component.
 * @module components/View/ReservationTime
 */

import PropTypes from 'prop-types';
import {
  Card,
  StepperContainer,
  StepperContent,
  CardBody,
  Input,
  Spinner,
} from 'design-react-kit';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { expandToBackendURL } from '@plone/volto/helpers';
import {
  getAvaiableSlots,
  getBookings,
} from 'comune-pordenone-plone-theme/actions';
import cx from 'classnames';

const messages = defineMessages({
  avaiableAppointments: {
    id: 'avaiableAppointments',
    defaultMessage: 'Appuntamenti disponibili',
  },
  avaiableAppointments_required: {
    id: 'avaiableAppointments_required',
    defaultMessage: 'Appuntamenti disponibili *',
  },
  office: {
    id: 'office',
    defaultMessage: 'Ufficio',
  },
  officeDescription: {
    id: 'officeDescription',
    defaultMessage: "Scegli l'ufficio a cui vuoi richiedere l'appuntamento",
  },
  officeType: {
    id: 'officeType',
    defaultMessage: 'Tipologia di ufficio',
  },
  go_back: {
    id: 'go_back',
    defaultMessage: 'Indietro',
  },
  save_request: {
    id: 'save_request',
    defaultMessage: 'Salva richiesta',
  },
  advance: {
    id: 'advance',
    defaultMessage: 'Avanti',
  },
  infoPrivacy: {
    id: 'infoPrivacy',
    defaultMessage: 'Informativa privacy',
  },
  january: {
    id: 'january',
    defaultMessage: 'Gennaio',
  },
  february: {
    id: 'february',
    defaultMessage: 'Febbraio',
  },
  march: {
    id: 'march',
    defaultMessage: 'Marzo',
  },
  april: {
    id: 'april',
    defaultMessage: 'Aprile',
  },
  may: {
    id: 'may',
    defaultMessage: 'Maggio',
  },
  june: {
    id: 'june',
    defaultMessage: 'Giugno',
  },
  july: {
    id: 'july',
    defaultMessage: 'Luglio',
  },
  august: {
    id: 'august',
    defaultMessage: 'Agosto',
  },
  september: {
    id: 'september',
    defaultMessage: 'Settembre',
  },
  october: {
    id: 'october',
    defaultMessage: 'Ottobre',
  },
  november: {
    id: 'november',
    defaultMessage: 'Novembre',
  },
  december: {
    id: 'december',
    defaultMessage: 'Dicembre',
  },
  selectMonth: {
    id: 'selectMonth',
    defaultMessage: 'Seleziona un mese',
  },
  noDaysAvail: {
    id: 'noDaysAvail',
    defaultMessage: 'Nessun appuntamento disponibile per questo mese.',
  },
  required_field: {
    id: 'required_field',
    defaultMessage: 'Questo campo è obbligatorio',
  },
});

/**
 * ReservationTime view component class.
 * @function ReservationTime
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const ReservationTime = ({
  content,
  currentState,
  selectedAppointment,
  setSelectedAppointment,
  filteredAppointments,
  setFilteredAppointments,
  months,
  selectedMonth,
  setSelectedMonth,
  formatAppointmentDate,
  setFormError,
  showFormError,
  setShowFormError,
  startDateParam,
  setStartDateParam,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const baseURL = expandToBackendURL(content?.[`@id`]);
  const appointmentsAvailable = useSelector(
    (state) => state.avaiableSlots.result.items,
  );

  // checks avaiable slots request status
  const avaiableSlotsLoading = useSelector(
    (state) => state.avaiableSlots.loading,
  );

  /**
   * Fetches available slots based on the current start date parameter.
   */
  useEffect(() => {
    dispatch(getAvaiableSlots(baseURL, startDateParam));
    dispatch(getBookings(baseURL));
  }, [startDateParam]);

  /**
   * Filters appointments by the selected month and updates the list of filtered appointments.
   */
  useEffect(() => {
    if (selectedMonth) {
      const filtered = appointmentsAvailable?.filter((appointment) => {
        const appointmentMonth = appointment.split('-')[1];
        const appointmentYear = appointment.split('-')[0];
        return `${appointmentYear}-${appointmentMonth}` === selectedMonth;
      });
      setFilteredAppointments(filtered);
    } else {
      setFilteredAppointments([]);
    }
  }, [selectedMonth, appointmentsAvailable]);

  /**
   * Handles changes to the selected month, updating start date and setting selected month state.
   * @param {string} value - The selected month in YYYY-MM format.
   */
  const handleMonthChange = (value) => {
    if (value) {
      const [year, month] = value.split('-');
      const firstDayOfMonth = new Date(year, month - 1, 1);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      // If first day of selected month is before today, use today's date
      const startDate = firstDayOfMonth < today ? today : firstDayOfMonth;

      // Format date as YYYY-MM-DD
      const formattedDate = `${startDate.getFullYear()}-${String(
        startDate.getMonth() + 1,
      ).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;
      setStartDateParam(`start=${formattedDate}`);
      setSelectedMonth(value);
    }
  };

  /**
   * Handles appointment selection, setting the selected appointment and focusing the appointment input.
   * @param {object} event - Event object containing the selected appointment data.
   * @param {string} id - The ID of the appointment element to focus.
   */
  const handleAppointmentSelection = (event, id) => {
    setFormError(true);
    setSelectedAppointment(event);
    if (selectedAppointment) {
      setFormError(false);
      setShowFormError(false);
    }
    const element = document.getElementById(id);
    if (element) {
      element.focus({ preventScroll: true });
    }
  };

  useEffect(() => {
    if (currentState === 1) {
      setFormError(true);
      if (selectedAppointment) {
        setFormError(false);
        setShowFormError(false);
      }
    }
  }, [setFormError, selectedAppointment]);

  return currentState === 1 ? (
    <RichTextSection
      tag_id={intl.formatMessage(messages.avaiableAppointments)}
      field="text"
      title={intl.formatMessage(messages.avaiableAppointments)}
      show_title={false}
      className={'StepperContainer'}
    >
      <StepperContainer>
        <StepperContent
          className="steppers-content border-0 mb-4 h-auto"
          aria-live="polite"
        >
          <div className="col-10">
            <section className="it-page-section">
              <h2>
                {intl.formatMessage(messages.avaiableAppointments_required)}
              </h2>
              <Card className="card p-big reservationCard-style">
                <CardBody className="px-0 mt-4">
                  <div className="select-wrapper">
                    <select
                      placeholder={intl.formatMessage(messages.selectMonth)}
                      id="time-option"
                      className={cx('p-0 col-12', {
                        border: showFormError,
                        'border-danger': showFormError,
                      })}
                      value={selectedMonth}
                      onChange={(event) => {
                        handleMonthChange(event.target.value);
                      }}
                      valid={showFormError}
                    >
                      <option value="">
                        {intl.formatMessage(messages.selectMonth)}
                      </option>
                      {months?.map((month) => (
                        <option key={month?.value} value={month.value}>
                          {month?.label}
                        </option>
                      ))}
                    </select>
                    {showFormError && (
                      <p
                        htmlFor="time-option"
                        className="form-feedback just-validate-error-label form-text form-feedback just-validate-error-label"
                      >
                        {intl.formatMessage(messages.required_field)}
                      </p>
                    )}
                  </div>
                  {selectedMonth && (
                    <div className="cmp-card-radio-list mt-4">
                      <div className="card p-3">
                        <div className="card-body p-0">
                          <div className="form-check m-0 d-flex flex-wrap justify-content-center">
                            {avaiableSlotsLoading ? (
                              <Spinner className="pb-4" active small />
                            ) : (
                              <>
                                {filteredAppointments?.length > 0 ? (
                                  filteredAppointments.map((appointment) => (
                                    <div
                                      className="radio-body py-3 col-6"
                                      key={`id-${appointment}`}
                                    >
                                      <Input
                                        type="radio"
                                        id={`appointment-${appointment}`}
                                        name="selectedAppointment"
                                        value={appointment}
                                        onChange={(e) =>
                                          handleAppointmentSelection(
                                            e?.target?.value,
                                            `appointment-${appointment}`,
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={`appointment-${appointment}`}
                                      >
                                        <strong>
                                          {formatAppointmentDate(appointment)}
                                        </strong>
                                      </label>
                                    </div>
                                  ))
                                ) : (
                                  <p>
                                    {intl.formatMessage(messages.noDaysAvail)}
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </section>
          </div>
        </StepperContent>
      </StepperContainer>
    </RichTextSection>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ReservationTime.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }),
  currentState: PropTypes.number.isRequired,
};

export default ReservationTime;
