import PropTypes from 'prop-types';
import { Card, Icon, CardBody, CardTitle } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  luoghi: {
    id: 'luogo',
    defaultMessage: 'Luogo',
  },
});

const PNNewsLocation = ({ content }) => {
  const intl = useIntl();
  return content?.locations?.title ? (
    <>
      <h5>{intl.formatMessage(messages.luoghi)}</h5>
      <Card
        className="card card-teaser shadow p-4 mt-3 rounded attachment"
        noWrapper={true}
        tag="div"
      >
        <Icon
          icon="it-map-marker-circle"
          alt={intl.formatMessage(messages.luoghi)}
          title={intl.formatMessage(messages.luoghi)}
        />
        <CardBody tag="div">
          <CardTitle tag="h5" color="red">
            {content?.locations?.title}
          </CardTitle>
        </CardBody>
      </Card>
    </>
  ) : (
    <></>
  );
};

PNNewsLocation.propTypes = {
  content: PropTypes.shape({
    ulteriori_informazioni: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
  }).isRequired,
};

export default PNNewsLocation;
