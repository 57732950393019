/* eslint-disable react-hooks/exhaustive-deps */
/**
 * MyArea component.
 * @module components/Features/MyArea/MyArea
 *
 * Page which displays personal area page.
 *
 */
import { Container, TabContent, TabPane } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import ActivitiesPage from './ActivitiesPage';
import DeskPage from './DeskPage';
import MessagesPage from './MessagesPage';
import MyAreaTab from './MyAreaTab';
import PropTypes from 'prop-types';
import ServicesPage from './ServicesPage';
import cx from 'classnames';
import { getUser } from '@plone/volto/actions';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';

const messages = defineMessages({
  fiscalNumber: {
    id: 'fiscalNumber',
    defaultMessage: 'CF: ',
  },
  myArea: {
    id: 'myArea',
    defaultMessage: 'Area personale',
  },
});

const TABS = {
  DESK: 'desk-tab',
  MESSAGES: 'messages-tab',
  ACTIVITIES: 'activities-tab',
  SERVICES: 'services-tab',
};

const MyArea = (props) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState('desk-tab');
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const [paymentStatus, setPaymentStatus] = useState();

  const payment_params = queryString.parse(hash);

  const userSession = useSelector((state) => state.portalUser);
  /**
   * The following code is needed for standard authentication
   */
  const ploneUserId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );

  const userLogged = useSelector((state) => state.users.user);
  const userLoggedSt = useSelector((state) => state.users);

  useEffect(() => {
    if (payment_params?.payment_success !== undefined) {
      setActiveTab(TABS.ACTIVITIES);
      setPaymentStatus('success');
    } else if (payment_params?.payment_failure !== undefined) {
      setActiveTab(TABS.ACTIVITIES);
      setPaymentStatus('failure');
    }
  }, []);

  useEffect(() => {
    if (
      !userLoggedSt?.get?.loading &&
      userSession?.isAuthenticated === false &&
      ploneUserId
    ) {
      dispatch(getUser(ploneUserId));
    }
  }, [ploneUserId, userSession.isAuthenticated]);

  const rightHeaderHasContent = false;
  return (
    <>
      <Container className="my-4">
        <Helmet title={intl.formatMessage(messages.myArea)} />
        <div className="PageHeaderWrapper row justify-content-center">
          <div className=" col-12 col-lg-10">
            <div
              className={cx('title-description-wrapper', {
                'col-lg-6': rightHeaderHasContent,
                'col-lg-12': !rightHeaderHasContent,
              })}
            >
              <h1>{`${userSession.user?.first_name} ${userSession.user?.last_name}`}</h1>
              <p>
                <strong>
                  {intl.formatMessage(messages.fiscalNumber)}
                  {`${userSession.user?.fiscal_number}`}
                </strong>
              </p>
            </div>
          </div>
        </div>
        {/* TAB SECTION */}
        <div className="col-12">
          <MyAreaTab activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabContent activeTab={activeTab}>
            <TabPane tabId={TABS.DESK} className="p-3">
              <DeskPage />
            </TabPane>
            <TabPane tabId={TABS.MESSAGES} className="p-3">
              <MessagesPage />
            </TabPane>
            <TabPane tabId={TABS.ACTIVITIES} className="p-3">
              <ActivitiesPage paymentStatus={paymentStatus} />
            </TabPane>
            <TabPane tabId={TABS.SERVICES} className="p-3">
              <ServicesPage />
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
MyArea.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default MyArea;
