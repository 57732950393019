/**
 * ReservationDetails view component.
 * @module components/View/ReservationDetails
 */

import PropTypes from 'prop-types';
import {
  Card,
  StepperContainer,
  StepperContent,
  CardHeader,
  CardBody,
  TextArea,
} from 'design-react-kit';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import cx from 'classnames';

const messages = defineMessages({
  reason: {
    id: 'reason',
    defaultMessage: 'Motivo *',
  },
  reasonDescription: {
    id: 'reasonDescription',
    defaultMessage: "Scegli il motivo dell'appuntamento",
  },
  details: {
    id: 'details',
    defaultMessage: 'Dettagli',
  },
  detailsDescription: {
    id: 'detailsDescription',
    defaultMessage: 'Aggiungi ulteriori dettagli',
  },
  maxLenght: {
    id: 'maxLenght',
    defaultMessage: 'Inserire massimo 200 caratteri',
  },
  choseOption: {
    id: 'choseOption',
    defaultMessage: 'Seleziona opzione',
  },
  required_field: {
    id: 'required_field',
    defaultMessage: 'Questo campo è obbligatorio',
  },
});

/**
 * ReservationDetails view component class.
 * @function ReservationDetails
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ReservationDetails = ({
  content,
  currentState,
  selectedType,
  setSelectedType,
  setMoreDetails,
  setFormError,
  showFormError,
  setShowFormError,
}) => {
  const intl = useIntl();

  // handles selection of the appointment type
  const handleSelectedType = (event) => {
    event.preventDefault();
    const selectedTypeName = event.target.value;
    const selectedType = content?.booking_types.find(
      (type) => type.name === selectedTypeName,
    );
    setSelectedType(selectedType);
    if (selectedType) {
      setShowFormError(false);
    }
  };

  // set more details value
  const handleMoreDetails = (event) => {
    setMoreDetails(event.target.value);
  };

  // manage error statement
  useEffect(() => {
    if (currentState === 2) {
      setFormError(true);
      if (selectedType) {
        setFormError(false);
        setShowFormError(false);
      }
    }
  }, [currentState, selectedType]);

  return currentState === 2 ? (
    <>
      <RichTextSection
        tag_id={intl.formatMessage(messages.details)}
        field="text"
        title={intl.formatMessage(messages.details)}
        show_title={false}
      >
        <StepperContainer>
          <StepperContent
            className="steppers-content border-0"
            aria-live="polite"
          >
            <div className="col-10">
              <section className="it-page-section">
                <Card className="card p-big reservationCard-style">
                  <CardHeader className="card-header border-0 p-0 mb-lg-30">
                    <h2>{intl.formatMessage(messages.reason)}</h2>
                    <p className="subtitle-small  mb-0">
                      {intl.formatMessage(messages.reasonDescription)}
                    </p>
                  </CardHeader>
                  <CardBody className="px-0 my-4">
                    <div className="select-wrapper">
                      <select
                        placeholder={intl.formatMessage(messages.choseOption)}
                        id={intl.formatMessage(messages.choseOption)}
                        className={cx('p-0 col-10', {
                          border: showFormError,
                          'border-danger': showFormError,
                        })}
                        onChange={handleSelectedType}
                        valid={showFormError}
                      >
                        <option
                          id={`option-${intl.formatMessage(
                            messages.choseOption,
                          )}`}
                          key={`option-${intl.formatMessage(
                            messages.choseOption,
                          )}`}
                          className="p-0 col-10"
                        >
                          {intl.formatMessage(messages.choseOption)}
                        </option>
                        {content?.booking_types?.map(
                          (type) =>
                            type?.hidden === false && (
                              <option
                                placeholder={`Seleziona ${type?.name}`}
                                id={`${type?.name}`}
                                key={`${type?.name}`}
                                className="p-0 col-10"
                              >
                                {type?.name}
                              </option>
                            ),
                        )}
                      </select>
                      {showFormError && (
                        <p
                          htmlFor="time-option"
                          className=" form-feedback just-validate-error-label form-text form-feedback just-validate-error-label"
                        >
                          {intl.formatMessage(messages.required_field)}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </section>
            </div>
          </StepperContent>
        </StepperContainer>
        <StepperContainer>
          <StepperContent
            className="steppers-content border-0"
            aria-live="polite"
          >
            <div className="col-10">
              <section className="it-page-section">
                <Card className="card p-big reservationCard-style">
                  <CardHeader className="card-header border-0 p-0 mb-lg-30">
                    <h2>{intl.formatMessage(messages.details)}</h2>
                    <p className="subtitle-small  mb-0">
                      {intl.formatMessage(messages.detailsDescription)}
                    </p>
                  </CardHeader>
                  <CardBody className="px-0 mt-4">
                    <div className="select-wrapper">
                      <TextArea
                        id={intl.formatMessage(messages.details)}
                        infoText={intl.formatMessage(messages.maxLenght)}
                        label={' '}
                        onChange={handleMoreDetails}
                      />
                    </div>
                  </CardBody>
                </Card>
              </section>
            </div>
          </StepperContent>
        </StepperContainer>
      </RichTextSection>
    </>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ReservationDetails.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default ReservationDetails;
