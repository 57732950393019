import { defineMessages, useIntl } from 'react-intl';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  RichTextSection,
  ContactLink,
  useSideMenu,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  commissionMembers: {
    id: 'commissionMembers',
    defaultMessage: 'Membri della commissione',
  },
  textInfo: {
    id: 'textInfo',
    defaultMessage: 'Informazioni',
  },
  president: {
    id: 'president',
    defaultMessage: 'Presidente',
  },
  vice: {
    id: 'vice',
    defaultMessage: 'Vice Presidente',
  },
});

const PNAdministratorsViewBoardCommittee = ({ content }) => {
  const intl = useIntl();
  return content.viewtype.title === 'Commissione' ||
    (content.viewtype.title === 'commissione' &&
      content?.Administrators?.length > 0) ? (
    <>
      <RichTextSection
        tag_id="commission-members-list"
        title={intl.formatMessage(messages.commissionMembers)}
        show_title={true}
      >
        <div class="it-list-wrapper">
          <ul className="it-list commission-list p-0">
            {content.Administrators.map((item, index) => (
              <li>
                <div class="list-item">
                  <div class="it-right-zone align-items-start">
                    <span class="text">
                      <a href={flattenToAppURL(item?.url)}>{item?.name}</a>
                      {item?.president === true ? (
                        <em>{intl.formatMessage(messages.president)}</em>
                      ) : (
                        ''
                      )}
                      {item?.vice === true ? (
                        <em>{intl.formatMessage(messages.vice)}</em>
                      ) : (
                        ''
                      )}
                    </span>
                    <span className="it-multiple">
                      <span className="metadata align-items-start">
                        <p>
                          <ContactLink email={item?.email} label={false} />
                        </p>
                        <p>
                          <ContactLink tel={item?.phone} label={false} />
                        </p>
                      </span>
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </RichTextSection>
    </>
  ) : (
    <></>
  );
};

PNAdministratorsViewBoardCommittee.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorsViewBoardCommittee;
