/**
 * Form widgets Payment widget module.
 * @module formwidgets/paymentwidget
 *
 */
import { CardModel, ProcessInstanceModel, WidgetModel } from '../models';
import { useEffect, useState } from 'react';

import { Spinner } from 'design-react-kit';
import { makePagoPARequest } from '../proxies';

export const PaymentWidget = ({ widget, context }: { widget: WidgetModel; context: CardModel | ProcessInstanceModel | object }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    makePagoPARequest(
      widget.IUV,
      context._type,
      context._id,
      widget.LinkAttribute,
    ).then((response: { success: boolean; data: { redirectUrl: string } }) => {
      if (response?.success) {
        window.location.href = response.data.redirectUrl;
        setHasErrors(false);
      } else {
        setHasErrors(true);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <div className="align-middle" hidden={!isLoading}>
        <p className="mb-3">Caricamento in corso</p>
        <Spinner active double />
      </div>
      <p hidden={isLoading || hasErrors}>Tra poco verrai rediretto alla pagina del servizio PagoPA.</p>
      <p hidden={isLoading || !hasErrors}>Si sono verificati degli errori.</p>
    </>
  );
};
