import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardTagsHeader,
  CardTag,
  CardText,
} from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  label_dependings: {
    id: 'label_dependings',
    defaultMessage: 'Dipende da',
  },
});

const PNOfficeParentOffice = ({ content }) => {
  const intl = useIntl();
  const parentOffice = content?.parent['@type'] === 'PNOffice';
  return parentOffice ? (
    <RichTextSection
      tag_id={'title-parent-office'}
      title={intl.formatMessage(messages.label_dependings)}
      show_title={true}
    >
      <Row>
        <Col lg="6" xs="12">
          <Card className="card-bg card-big no-after rounded">
            <CardBody className="p-4">
              <CardTagsHeader className="mb-3">
                <CardTag href={content?.parent['@id']}>
                  {content?.parent?.type_title}
                </CardTag>
              </CardTagsHeader>
              <CardTitle tag="h5">
                <a href={content?.parent['@id']}>{content?.parent?.title}</a>
              </CardTitle>
              <CardText>{content?.parent?.description}</CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </RichTextSection>
  ) : (
    <></>
  );
};

PNOfficeParentOffice.propTypes = {
  content: PropTypes.shape({
    parent: PropTypes.shape({
      '@type': PropTypes.string,
      '@id': PropTypes.string,
      type_title: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
};

export default PNOfficeParentOffice;
